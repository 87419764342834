<script setup lang="ts">
import { toRef } from "vue";
import { useI18n } from "vue-i18n";
import { useBlueColorTranslations } from "@/composables/translations/useBlueColorTranslations";
import { BlueColor } from "@/types/_generated/api";
import { ModuleColorTranslations } from "@/types/ModuleTranslations";
import BaseAccordion from "@/components/base/BaseAccordion/BaseAccordion.vue";
import ModMultiselect from "@/components/mods/Multiselect/ModMultiselect.vue";

interface Props {
  recommendationColors: Set<BlueColor>;
  translations: ModuleColorTranslations | null;
}

const props = defineProps<Props>();

defineEmits<{
  "update:selected": [options: BlueColor[]];
}>();

const { t } = useI18n({ useScope: "global" });

const { getLabel } = useBlueColorTranslations(toRef(() => props.translations));

const blueColors = Object.values(BlueColor).map((color) => ({
  value: color,
  label: getLabel(color),
}));
</script>

<template>
  <BaseAccordion :label="t('userWeb.recommendation.blueRating')">
    <ModMultiselect
      :options="blueColors"
      :selected-options="recommendationColors"
      @update:selected="$emit('update:selected', $event)"
    />
  </BaseAccordion>
</template>
