<script setup lang="ts">
import { computed, onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useBroadcastService } from "@/composables/useBroadcastService";
import { useTableFilter } from "@/composables/useTableFilter";
import { authorize } from "@/plugins/can";
import { siteExemptionEvents } from "@/services/siteExemptions";
import { SiteExemptionDto } from "@/types/_generated/api";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseFilter from "@/components/base/BaseFilter.vue";
import BaseGridTable from "@/components/base/BaseGridTable/BaseGridTable.vue";
import EditIconButton from "@/components/shared/EditIconButton.vue";
import { useSiteExemptions } from "../composables/useSiteExemptions";
import SiteExemptionEditDrawer from "./SiteExemptionEditDrawer.vue";

const { t } = useI18n({ useScope: "global" });

const props = defineProps<{ siteId: number; moduleId: number }>();

const { exemptions, columns, isLoading, loadExemptions } = useSiteExemptions(props.siteId);
const drawerEditItem = ref<SiteExemptionDto | null>(null);
const showDrawer = ref(false);

onBeforeMount(loadExemptions);
useBroadcastService(siteExemptionEvents, loadExemptions);

const { result, searchPhrase, searchKey, setSearchPhrase, setSearchKey, searchKeys } =
  useTableFilter(exemptions, columns);

const openEditDrawer = (entity: SiteExemptionDto | null) => {
  drawerEditItem.value = entity;
  showDrawer.value = true;
};

const closeEditDrawer = () => {
  showDrawer.value = false;
  drawerEditItem.value = null;
};

const canEdit = computed(() => authorize.hasModulePermission(props.moduleId, "createSite"));
</script>

<template>
  <div>
    <BaseGridTable :rows="result.result" :columns="columns" :is-loading="isLoading">
      <template #controls>
        <BaseFilter
          :search-phrase="searchPhrase"
          :search-key="searchKey"
          :search-keys="searchKeys"
          @update:search-phrase="setSearchPhrase($event)"
          @update:search-key="setSearchKey($event)"
        />
      </template>

      <template #control-actions>
        <BaseButton
          v-if="canEdit"
          data-test="create-site-exemptions-button"
          @click="openEditDrawer(null)"
        >
          {{ t("sites.exemptions.newExemption") }}
        </BaseButton>
      </template>

      <template #actions="{ row }">
        <EditIconButton
          v-if="canEdit"
          data-test="edit-site-exemptions-button"
          :title="t('common.actions.edit')"
          @click="openEditDrawer(row)"
        />
      </template>
    </BaseGridTable>
  </div>

  <SiteExemptionEditDrawer
    v-if="showDrawer"
    :site-id="siteId"
    :exemption="drawerEditItem"
    @close="closeEditDrawer"
  />
</template>
