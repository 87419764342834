<script setup lang="ts">
import { computed, onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useGetUser } from "@/composables/services/useGetUser";
import { useDialog } from "@/composables/useDialog";
import { useUnsavedChanges } from "@/composables/useUnsavedChanges";
import { useVuelidate } from "@/composables/useVuelidateWithFocusError";
import { cloneChangedProperties } from "@/helpers/cloneChangedProperties";
import { archiveUser, createUser, updateUser } from "@/services/users";
import { useAppStore } from "@/stores/app";
import { CreateUser, LoginType, Role, UpdateUser, UserDto } from "@/types/_generated/api";
import { email, notEmail, required, requiredIf, mobile } from "@/validation/i18n-validators";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseCard from "@/components/base/BaseCard/BaseCard.vue";
import BaseCheckbox from "@/components/base/BaseCheckbox/BaseCheckbox.vue";
import BaseDrawer from "@/components/base/BaseDrawer/BaseDrawer.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";
import PasswordForm from "@/components/shared/PasswordForm.vue";
import { type EditUserForm, useInitializeUserForm } from "../composables/useInitializeUserForm";
import UserLanguageSelect from "./UserLanguageSelect.vue";
import UserLoginTypeSelect from "./UserLoginTypeSelect.vue";
import UserModulePermissionEditDrawer from "./UserModulePermissionEditDrawer.vue";
import UserPasswordModal from "./UserPasswordModal.vue";
import UserRoleSelect from "./UserRoleSelect.vue";

const props = defineProps<{
  userId: number | null;
}>();

const emit = defineEmits<{ (event: "close"): void }>();

const userIdJustCreated = ref<number | null>(null);
const showModulePermissionEditDrawer = ref(false);

const app = useAppStore();
const dialog = useDialog();
const { t } = useI18n({ useScope: "global" });
const { setCompareBase, discardUnsavedChanges } = useUnsavedChanges();

const { isLoadingUser, getUser } = useGetUser();
const { initializeUserForm } = useInitializeUserForm();

const form = ref<EditUserForm>(initializeUserForm());
const user = ref<UserDto | null>(null);
const sendPasswordResetEmail = ref(true);
const password1 = ref("");
const password2 = ref("");
const showPasswordModal = ref(false);
const isEdit = computed(() => !!props.userId);

const validations = {
  loginType: { required },
  email: { email, required },
  role: { required },
  firstName: { required },
  lastName: { required },
  mobilePhoneNumber: { mobile },

  language: { required },
  userName: {
    required: requiredIf(() => form.value?.loginType !== LoginType.Blue),
    notEmail,
  },
};

onBeforeMount(async () => {
  if (props.userId) {
    user.value = await getUser(props.userId);
    form.value = initializeUserForm(user.value);
  }

  setCompareBase(form);

  v$.value.$reset();
});

const { v$, addRef, focusError } = useVuelidate(validations, form, {
  $autoDirty: true,
});

const saveUser = async (form: EditUserForm) => {
  v$.value.$validate();

  if (v$.value.$invalid) {
    focusError();
    return;
  }

  // Is create
  if (!isEdit.value) {
    if (form.loginType === LoginType.Blue && !sendPasswordResetEmail.value) {
      form.password = password1.value;
    }

    const userId = await createUser(form as CreateUser);

    if (userId) {
      userIdJustCreated.value = userId;
      showModulePermissionEditDrawer.value = true;
      user.value = await getUser(userId);
    }
  } else {
    const isSuccess = await editUser(form);

    if (isSuccess) {
      user.value = await getUser(props.userId!);
    }
  }

  setCompareBase(form);
};

const editUser = async (form: EditUserForm): Promise<boolean> => {
  const updatedUser = cloneChangedProperties(form, user.value, "userId");
  const hasChanges = Object.keys(updatedUser).length > 1;

  if (form.loginType === LoginType.Blue) {
    updatedUser.userName = null;
  } else {
    updatedUser.userName = form.userName;
  }

  if (!hasChanges) {
    return true;
  }

  updatedUser.userId = form.id;

  return await updateUser(updatedUser as UpdateUser);
};

const setLoginType = (loginType: LoginType) => {
  form.value!.loginType = loginType;

  const isChangeBackToIntOrExt =
    user.value?.loginType === LoginType.Blue && loginType !== LoginType.Blue;

  if (isChangeBackToIntOrExt) {
    form.value!.userName = user.value!.userName;
  }

  if (!isEdit.value && loginType === LoginType.Blue) {
    sendPasswordResetEmail.value = true;
  }
};

const isChangeToLoginTypeBlue = computed(
  () => form.value.loginType === LoginType.Blue && user.value?.loginType !== LoginType.Blue,
);

const title = computed(() =>
  isEdit.value
    ? t("users.editUserDrawerTitle", { fullName: user.value?.fullName })
    : t("users.newUser"),
);

const resetPassword = () => {
  sendPasswordResetEmail.value = true;
  password1.value = "";
  password2.value = "";
};

const closeDrawer = async () => {
  if (await discardUnsavedChanges(form)) {
    emit("close");
  }
};

const archive = async (userId: number) => {
  const isConfirmed = await dialog.openDialog({
    title: t("common.actions.archive"),
    description: t("users.archiveUsersPrompt"),
  });

  if (isConfirmed) {
    await archiveUser(userId);
    emit("close");
  }
};
</script>

<template>
  <BaseDrawer
    v-show="!showModulePermissionEditDrawer"
    class="user-edit-drawer"
    :title="title"
    :is-loading="isLoadingUser"
    data-test="user-edit-drawer"
    @close="closeDrawer"
  >
    <section v-if="form" class="user-edit-drawer__form">
      <div class="user-edit-drawer__form__left-column">
        <BaseCard :title="t('common.account')" has-padding>
          <UserLoginTypeSelect
            :ref="(el) => addRef(el, 'loginType')"
            :value="form.loginType"
            required
            :errors="v$.loginType.$errors"
            @update:value="setLoginType"
          />
          <BaseTextField
            :ref="(el) => addRef(el, 'email')"
            v-model:value="form.email"
            required
            :label="t('common.email')"
            :errors="v$.email.$errors"
            data-test="user-edit-email"
          />
          <BaseTextField
            v-if="form.loginType !== LoginType.Blue"
            :ref="(el) => addRef(el, 'userName')"
            v-model:value="form.userName"
            required
            :label="t('common.username')"
            data-test="user-edit-userName"
            :errors="v$.userName.$errors"
          />
          <!-- Disabled until saving when switching from LoginType.INT/EXT to LoginType.Blue -->
          <BaseButton
            v-if="isEdit && form.loginType === LoginType.Blue"
            :disabled="isChangeToLoginTypeBlue"
            variant="outlined"
            class="user-edit-drawer__form__button"
            data-test="user-edit-change-password"
            :title="isChangeToLoginTypeBlue ? t('users.changePasswordWarning') : ''"
            @click="showPasswordModal = true"
          >
            {{ t("users.changePassword") }}
          </BaseButton>

          <BaseCheckbox
            v-if="form.loginType === LoginType.Blue && !isEdit"
            data-test="send-password-reset-email"
            :checked="sendPasswordResetEmail"
            :label="t('users.explicitlySetPassword')"
            @change="
              (evt) => {
                resetPassword();
                sendPasswordResetEmail = evt;
              }
            "
          />

          <PasswordForm
            v-if="form.loginType === LoginType.Blue && !sendPasswordResetEmail && !isEdit"
            v-model:password1="password1"
            v-model:password2="password2"
            :show-requirements="false"
          />
        </BaseCard>

        <BaseCard :title="t('users.access')" has-padding>
          <UserRoleSelect
            :ref="(el) => addRef(el, 'role')"
            :value="form.role"
            :role="app.user!.role"
            :errors="v$.role.$errors"
            @update:value="form!.role = $event"
          />
          <BaseCheckbox
            v-if="form.role === Role.EXT"
            id="user-allow-userweb"
            :label="t('users.allowUserWeb')"
            :checked="form.allowUserWeb"
            data-test="user-edit-allowUserWeb"
            @change="form.allowUserWeb = $event"
          />

          <BaseCheckbox
            v-if="form.role === Role.EXT"
            id="user-allow-company-comment"
            :label="t('users.allowRmComment')"
            :checked="form.allowRmComment"
            data-test="user-edit-allowCompanyComment"
            @change="form.allowRmComment = $event"
          />

          <BaseCheckbox
            v-if="form.role === Role.EXT"
            id="user-allow-report-mail"
            :label="t('users.allowReportMail')"
            :checked="form.allowReportMail"
            data-test="user-edit-allowReportMail"
            @change="form.allowReportMail = $event"
          />
          <BaseCheckbox
            id="user-locked-out"
            :label="t('users.lockedOut')"
            :checked="form.isLockedOut"
            data-test="user-edit-isLockedOut"
            @change="form.isLockedOut = $event"
          />
          <span
            v-if="isEdit && user && user.isArchived"
            class="user-edit-drawer__form__archived-checkbox"
          >
            <BaseCheckbox
              id="user-is-archived"
              :label="t('common.archived')"
              :checked="form.isArchived ?? false"
              @change="form.isArchived = $event"
            />
          </span>

          <BaseButton
            v-if="isEdit && user?.role !== Role.SystemOwner && user?.role !== Role.SA"
            class="user-edit-drawer__form__button"
            variant="outlined"
            @click="showModulePermissionEditDrawer = true"
          >
            {{ t("users.permissions.editPermissions") }}
          </BaseButton>
        </BaseCard>
      </div>

      <div class="user-edit-drawer__form__right-column">
        <BaseCard :title="t('users.personalDetails')" has-padding>
          <BaseTextField
            :ref="(el) => addRef(el, 'firstName')"
            v-model:value="form.firstName"
            required
            :label="t('common.firstName')"
            :errors="v$.firstName.$errors"
            data-test="user-edit-firstName"
          />
          <BaseTextField
            :ref="(el) => addRef(el, 'lastName')"
            v-model:value="form.lastName"
            required
            :label="t('common.lastName')"
            :errors="v$.lastName.$errors"
            data-test="user-edit-lastName"
            trim
          />
          <BaseTextField
            v-model:value="form.phoneNumber"
            :label="t('common.phone')"
            data-test="user-edit-phone"
          />

          <BaseTextField
            :ref="(el) => addRef(el, 'mobilePhoneNumber')"
            v-model:value="form.mobilePhoneNumber"
            :errors="v$.mobilePhoneNumber.$errors"
            :label="t('common.mobile')"
            data-test="user-edit-mobile"
            @change:value="form.mobilePhoneNumber = ($event as string)?.replace(/\s/g, '')"
          />

          <UserLanguageSelect
            :ref="(el) => addRef(el, 'language')"
            :value="form.language"
            :errors="v$.language.$errors"
            required
            @update:value="form!.language = $event"
          />
        </BaseCard>

        <BaseCard :title="t('users.location')" has-padding>
          <BaseTextField
            v-model:value="form.country"
            :label="t('common.country')"
            data-test="user-edit-country"
          />
          <BaseTextField
            v-model:value="form.city"
            :label="t('common.city')"
            data-test="user-edit-city"
          />
        </BaseCard>

        <BaseCard :title="t('users.company')" has-padding>
          <BaseTextField
            v-model:value="form.company"
            :label="t('common.company')"
            data-test="user-edit-company"
          />
          <BaseTextField
            v-model:value="form.title"
            :label="t('common.title')"
            data-test="user-edit-title"
          />
        </BaseCard>
      </div>
    </section>

    <template #footer-left>
      <BaseButton data-test="user-edit-confirm" @click="() => saveUser(form!)">
        {{ isEdit ? t("common.actions.save") : t("common.actions.create") }}
      </BaseButton>

      <BaseButton variant="outlined" data-test="user-edit-cancel" @click="closeDrawer">
        {{ t("common.actions.cancel") }}
      </BaseButton>
    </template>

    <template #footer-right>
      <BaseButton
        v-if="
          user &&
          isEdit &&
          !user.isArchived &&
          $authorize.isSuperiorTo(Role.INT) &&
          $authorize.isSuperiorTo(user.role)
        "
        variant="outlined"
        color="error"
        @click="archive(userId!)"
      >
        {{ t("common.actions.archive") }}
      </BaseButton>
    </template>
  </BaseDrawer>

  <UserPasswordModal
    v-if="showPasswordModal"
    :user-id="userId"
    :show="showPasswordModal"
    @close="showPasswordModal = false"
  />

  <UserModulePermissionEditDrawer
    v-if="showModulePermissionEditDrawer && (userId || userIdJustCreated)"
    :user-id="userId || userIdJustCreated || 0"
    @close="
      () => {
        showModulePermissionEditDrawer = false;

        if (userIdJustCreated) {
          userIdJustCreated = null;
          emit('close');
        }
      }
    "
  />
</template>

<style scoped lang="scss">
.user-edit-drawer {
  &__panel {
    min-width: 48rem;
  }

  &__form {
    display: grid;
    gap: $spacing-4;
    padding: $spacing-1;

    .base-checkbox {
      margin: $spacing-2 $spacing-1;
    }

    &__button {
      margin-top: $spacing-3;
    }

    &__left-column {
      grid-column: 1;
    }

    &__right-column {
      grid-column: 2;
    }

    &__left-column,
    &__right-column {
      display: flex;
      flex-direction: column;
      gap: $spacing-4;
    }

    &__archived-checkbox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: $spacing-4;
      padding-bottom: $spacing-2;
    }

    @media (max-width: $screen-md) {
      width: 100%;
      flex-direction: column;
      justify-content: center;
      max-width: $screen-md;

      &__left-column,
      &__right-column {
        width: 100%;
      }
    }
  }
}

:deep(.base-card__content) {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;
}
</style>
