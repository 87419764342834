<script setup lang="ts">
import { ComputedRef, computed, onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useGetSurvey } from "@/composables/services/useGetSurvey";
import { useVuelidate } from "@/composables/useVuelidateWithFocusError";
import { formatDate } from "@/helpers/formatDate";
import { getLibraryFiles } from "@/services/library";
import { getSurveyEmailTemplateService, sendPreSurveyEmailService } from "@/services/surveys";
import {
  EmailAddressDto,
  LibraryFileDto,
  SendPreSurveyEmail,
  SurveyEmail,
} from "@/types/_generated/api";
import { Option } from "@/types/Option";
import { email, required } from "@/validation/i18n-validators";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseDrawer from "@/components/base/BaseDrawer/BaseDrawer.vue";
import BaseEditor from "@/components/base/BaseEditor/BaseEditor.vue";
import BaseFile from "@/components/base/BaseFile/BaseFile.vue";
import BaseLabel from "@/components/base/BaseLabel.vue";
import BasePill from "@/components/base/BasePills/BasePill.vue";
import BasePillDeleteButton from "@/components/base/BasePills/BasePillDeleteButton.vue";
import BasePillsInput from "@/components/base/BasePills/BasePillsInput.vue";
import BaseOption from "@/components/base/BaseSelect/BaseOption.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";

const props = defineProps<{
  surveyId: number;
}>();

const emits = defineEmits<{
  (event: "close"): void;
}>();

const { t } = useI18n();

const isLoadingTemplates = ref(false);

const recipientEmail = ref("");

const form = ref<SendPreSurveyEmail>({
  subject: "",
  body: "",
  recipients: [] as EmailAddressDto[],
  surveyId: 0,
  fileId: undefined,
});

const files = ref<LibraryFileDto[]>([]);

const { survey, getSurvey, isLoadingSurvey } = useGetSurvey();

const isLoading = computed(() => isLoadingSurvey.value || isLoadingTemplates.value);

onBeforeMount(async () => {
  await getSurvey(props.surveyId);

  if (!survey.value) {
    throw new Error("Survey not found");
  }

  const { siteId } = survey.value;
  form.value.surveyId = props.surveyId;

  isLoadingTemplates.value = true;

  const surveyEmailTemplateValue = await getSurveyEmailTemplateService(
    siteId,
    SurveyEmail.PreSurvey,
  );

  if (surveyEmailTemplateValue) {
    form.value.subject = surveyEmailTemplateValue.subject;
    form.value.body = surveyEmailTemplateValue.body;
  }

  files.value = (await getLibraryFiles(survey.value.moduleId, { fileExtensions: [".pdf"] })) ?? [];

  if (survey.value.participants) {
    form.value.recipients = survey.value.participants.map((participant) => {
      const alias =
        participant.firstName.length && participant.lastName.length
          ? `${participant.firstName} ${participant.lastName}`
          : participant.email;

      return {
        email: participant.email,
        alias,
      };
    });
  }

  isLoadingTemplates.value = false;
});

/// Validering
const validationField = computed(() => ({
  email: recipientEmail.value,
  ...form.value,
}));

const validations = {
  email: { email },
  subject: { required },
  body: { required },
  recipients: { required },
};

const { v$, addRef, focusError } = useVuelidate(validations, validationField);

const addRecipient = async (email: string) => {
  recipientEmail.value = email;
  await v$.value.email.$validate();

  if (v$.value.email.$invalid) {
    return;
  }

  form.value.recipients.push({ email, alias: email });
  v$.value.$reset();

  recipientEmail.value = "";
};

const send = async (form: SendPreSurveyEmail) => {
  await v$.value.$validate();

  if (v$.value.$invalid) {
    focusError();
    return;
  }

  const response = await sendPreSurveyEmailService(form);

  if (response) {
    emits("close");
  }
};

const fileOptions: ComputedRef<Option[]> = computed(() => [
  {
    title: t("survey.preSurveyEmail.noAttachment"),
    value: undefined,
  },
  ...files.value.map((file) => ({
    title: file.fileName,
    value: file.fileId,
  })),
]);

const selectFile = (value: string | undefined) => (form.value.fileId = value);

const file = computed(() =>
  form.value.fileId ? files.value.find((file) => file.fileId === form.value.fileId) : undefined,
);

const fileUrl = computed(() =>
  survey.value && form.value
    ? `/v1/modules/${survey.value.moduleId}/library-files/${form.value.fileId}`
    : "#",
);
</script>

<template>
  <BaseDrawer
    class="survey-pre-mail-drawer"
    :title="
      t('survey.preSurveyEmail.title', {
        survey: formatDate.asDateOnly(survey?.surveyDate),
      })
    "
    :is-loading="isLoading"
    :width="'30'"
    @close="$emit('close')"
  >
    <div class="survey-pre-mail-drawer__content">
      <small>{{ t("survey.preSurveyEmail.preSurveyEmailInfo") }} </small>
      <BasePillsInput
        required
        :errors="[...v$.email.$errors, ...v$.recipients.$errors]"
        :placeholder="'example@mail.com'"
        :label="t('survey.preSurveyEmail.recipients')"
        :values="form.recipients"
        :not-valid="v$.email.$invalid || v$.recipients.$invalid"
        @add="addRecipient"
      >
        <template #pill="{ value, index }">
          <BasePill :key="index" :label="value.alias" :title="value.email">
            <template #post>
              <BasePillDeleteButton
                @click="() => (form.recipients = form.recipients.filter((_, i) => i !== index))"
              />
            </template>
          </BasePill>
        </template>
      </BasePillsInput>

      <BaseTextField
        :ref="(el) => addRef(el, 'subject')"
        v-model:value="form.subject"
        :errors="v$.subject.$errors"
        required
        :label="t('survey.preSurveyEmail.subject')"
      />
      <BaseLabel>{{ t("survey.preSurveyEmail.body") }}</BaseLabel>
      <BaseEditor
        :ref="(el) => addRef(el, 'body')"
        :custom-height="{ min: '200px' }"
        :value="form.body"
        :errors="v$.body.$errors"
        @input="($event) => (form.body = $event)"
      />
      <BaseSelect
        :value="form.fileId"
        :label="t('survey.preSurveyEmail.attachmentPDFOnly')"
        :description="t('survey.preSurveyEmail.preSurveyAttachmentInfo')"
        @change="($event) => selectFile($event as string)"
      >
        <BaseOption v-for="option in fileOptions" :key="option.value" :value="option.value">
          {{ option.title }}
        </BaseOption>
      </BaseSelect>

      <BaseFile
        v-if="file"
        edit
        :file="file"
        :file-url="fileUrl"
        @delete="
          () => {
            form.fileId = undefined;
          }
        "
      />
    </div>

    <template #footer-left>
      <BaseButton @click="() => send(form)">
        {{ t("common.actions.send") }}
      </BaseButton>

      <BaseButton variant="outlined" @click="$emit('close')">
        {{ t("common.actions.cancel") }}
      </BaseButton>
    </template>
  </BaseDrawer>
</template>

<style scoped lang="scss">
.survey-pre-mail-drawer {
  &__content {
    padding: $spacing-6;
    display: flex;
    flex-direction: column;
    gap: $spacing-4;
  }
}
</style>
