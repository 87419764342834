<script setup lang="ts">
import { computed, reactive, type DeepReadonly } from "vue";
import { useI18n } from "vue-i18n";
import type { SitesData } from "@/composables/services/useGetSitesData";
import type { ModuleDto, RecommendationTrackingFilter } from "@/types/_generated/api";
import type { CustomDefinitionKey } from "@/types/SiteDefinitions";
import { isDeepEqual } from "@/helpers/isDeepEqual";
import BaseAccordion from "@/components/base/BaseAccordion/BaseAccordion.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseDivider from "@/components/base/BaseDivider.vue";
import UWDrawer from "@/components/base/UserWeb/Drawer/UWDrawer.vue";
import ModFilterNumberRange from "@/components/mods/Filter/NumberRange/ModFilterNumberRange.vue";
import ModFilterSiteDefinitions from "@/components/mods/Filter/SiteDefinitions/ModFilterSiteDefinitions.vue";
import { RecTrackingFilter } from "../../composables/useFilterRecTracking";
import RecTrackingFilterBlueRating from "./RecTrackingFilterBlueRating.vue";
import RecTrackingFilterDate from "./RecTrackingFilterDate.vue";
import RecTrackingFilterPrio from "./RecTrackingFilterPrio.vue";
import RecTrackingFilterRecHeadline from "./RecTrackingFilterRecHeadline.vue";
import RecTrackingFilterRecNumber from "./RecTrackingFilterRecNumber.vue";
import RecTrackingFilterResponseStatus from "./RecTrackingFilterResponseStatus.vue";
import RecTrackingFilterSurveyorStatus from "./RecTrackingFilterSurveyorStatus.vue";

const props = defineProps<{
  sitesData?: DeepReadonly<SitesData>;
  module: ModuleDto;
  filter: RecommendationTrackingFilter;
}>();

const emit = defineEmits<{
  closeDrawer: [void];
  applyFilter: [RecommendationTrackingFilter];
}>();

const { t } = useI18n({ useScope: "global" });

const pendingFilter = reactive<RecommendationTrackingFilter>({
  ...props.filter,
  excludedSiteDefinitions: { ...props.filter.excludedSiteDefinitions },
});

const applyFilter = (closeDrawer: () => void) => {
  emit("applyFilter", pendingFilter);
  closeDrawer();
};

const resetPendingFilter = () => Object.assign(pendingFilter, new RecTrackingFilter());
const disabledReset = computed(() => isDeepEqual(pendingFilter, new RecTrackingFilter()));

const updateExcludedSiteDefinitions = (key: CustomDefinitionKey, value: Set<string>) => {
  pendingFilter.excludedSiteDefinitions[key] = Array.from(value);
};
</script>

<template>
  <UWDrawer size="small" :drawer-title="t('common.filter')" @close-drawer="$emit('closeDrawer')">
    <div class="rec-tracking-filter-drawer">
      <RecTrackingFilterDate
        :survey-date-min="pendingFilter.surveyDateMin"
        :survey-date-max="pendingFilter.surveyDateMax"
        @update:start-date="pendingFilter.surveyDateMin = $event"
        @update:end-date="pendingFilter.surveyDateMax = $event"
      />
      <BaseDivider />

      <RecTrackingFilterBlueRating
        :recommendation-colors="new Set(pendingFilter.recommendationColors)"
        :translations="module"
        @update:selected="pendingFilter.recommendationColors = $event"
      />
      <RecTrackingFilterResponseStatus
        :site-response-statuses="pendingFilter.siteResponseStatuses"
        @update:selected="pendingFilter.siteResponseStatuses = $event"
      />
      <RecTrackingFilterSurveyorStatus
        :surveyor-statuses="pendingFilter.surveyorStatuses"
        @update:selected="pendingFilter.surveyorStatuses = $event"
      />

      <BaseAccordion :label="t('userWeb.recommendationTracking.filter.costBenefitAnalysis')">
        <div class="rec-tracking-filter-drawer__inputs">
          <ModFilterNumberRange
            :label="t('userWeb.recommendationTracking.filter.cost')"
            :min="pendingFilter.rcbaCostToCompleteMin"
            :max="pendingFilter.rcbaCostToCompleteMax"
            @update:min="pendingFilter.rcbaCostToCompleteMin = $event"
            @update:max="pendingFilter.rcbaCostToCompleteMax = $event"
          />
          <BaseDivider />

          <ModFilterNumberRange
            :label="t('userWeb.recommendationTracking.filter.costBenefitRatio')"
            :min="pendingFilter.rcbaRatioMin"
            :max="pendingFilter.rcbaRatioMax"
            @update:min="pendingFilter.rcbaRatioMin = $event"
            @update:max="pendingFilter.rcbaRatioMax = $event"
          />
        </div>
      </BaseAccordion>

      <BaseAccordion :label="t('userWeb.recommendationTracking.filter.other')">
        <div class="rec-tracking-filter-drawer__inputs">
          <RecTrackingFilterPrio
            :label="module?.priorityTranslation"
            :priority="pendingFilter.priority"
            @update:priority="pendingFilter.priority = $event"
          />
          <BaseDivider />

          <RecTrackingFilterRecNumber
            :recommendation-number="pendingFilter.recommendationNumber"
            @update:rec-number="pendingFilter.recommendationNumber = $event"
          />
          <BaseDivider />

          <RecTrackingFilterRecHeadline
            :recommendation-headline="pendingFilter.recommendationHeadline"
            @update:rec-headline="pendingFilter.recommendationHeadline = $event"
          />
        </div>
      </BaseAccordion>

      <template v-if="sitesData">
        <BaseDivider />
        <ModFilterSiteDefinitions
          :sites-data="sitesData"
          :excluded-sets="{
            custom1: new Set(pendingFilter.excludedSiteDefinitions.custom1),
            custom2: new Set(pendingFilter.excludedSiteDefinitions.custom2),
            custom3: new Set(pendingFilter.excludedSiteDefinitions.custom3),
            custom4: new Set(pendingFilter.excludedSiteDefinitions.custom4),
          }"
          @update:excluded-sets="updateExcludedSiteDefinitions"
        />
      </template>
    </div>

    <template #footer-left="{ onCloseDrawer }">
      <BaseButton @click="applyFilter(onCloseDrawer)">
        {{ t("userWeb.filter.apply") }}
      </BaseButton>

      <BaseButton variant="outlined" @click="onCloseDrawer">
        {{ t("common.actions.cancel") }}
      </BaseButton>
    </template>

    <template #footer-right>
      <BaseButton :disabled="disabledReset" variant="text" @click="resetPendingFilter">
        {{ t("common.resetFilter") }}
      </BaseButton>
    </template>
  </UWDrawer>
</template>

<style scoped lang="scss">
.rec-tracking-filter-drawer {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;

  &__inputs {
    display: flex;
    flex-direction: column;
    gap: $spacing-4;
    padding: $spacing-2;
    margin-top: $spacing-2;
  }
}
</style>
