<script setup lang="ts">
import { onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useGetModule } from "@/composables/services/useGetModule";
import { useGetRecommendations } from "@/composables/services/useGetRecommendations";
import { displayValue } from "@/helpers/displayValue";
import { SiteResponseStatus, SurveyorStatus } from "@/types/_generated/api";
import BaseBlueRatingStatusBadge from "@/components/base/BaseBlueRatingStatusBadge.vue";
import BaseDataDisplayListItem from "@/components/base/BaseDataDisplay/BaseDataDisplayListItem.vue";
import BaseSafeHtml from "@/components/base/BaseSafeHtml/BaseSafeHtml.vue";
import BaseStatusBadge from "@/components/base/BaseStatusBadge.vue";
import LatestRecommendationResponse from "@/components/shared/RecommendationResponse/LatestRecommendationResponse.vue";
import ResponseFileList from "@/components/shared/RecommendationResponse/ResponseFileList.vue";
import ExecSummaryCatRecStates from "./ExecSummaryCatRecStates.vue";
import ExecSummaryCatRecTitle from "./ExecSummaryCatRecTitle.vue";

const props = defineProps<{
  moduleId: number;
  surveyId: number;
  categoryId: number;
}>();

const { t } = useI18n({ useScope: "global" });

const { getRecommendations, recommendations, isLoadingRecommendations, hasError } =
  useGetRecommendations();

const { module, isLoadingModule, getModule } = useGetModule();

onMounted(() => {
  getRecommendations(props.surveyId, {
    categoryIds: [props.categoryId],
    surveyorStatuses: [
      SurveyorStatus.NotSet,
      SurveyorStatus.NoProgress,
      SurveyorStatus.UnderReview,
      SurveyorStatus.Rejected,
      SurveyorStatus.InProgress,
      SurveyorStatus.Abeyance,
    ],
  });
  getModule(props.moduleId);
});
</script>

<template>
  <div v-if="recommendations.length" class="cat-rec-list">
    <ExecSummaryCatRecTitle>
      {{ t("userWeb.executiveSummary.categoryRecommendations.recommendations") }}
    </ExecSummaryCatRecTitle>

    <template v-if="!isLoadingRecommendations && !isLoadingModule && !hasError">
      <div
        v-for="(recommendation, index) in recommendations"
        :key="index"
        class="cat-rec-list__recommendation"
      >
        <div>
          <h3 class="cat-rec-list__recommendation__title">
            {{ recommendation.fullPosition }}
          </h3>
        </div>

        <div class="cat-rec-list__recommendation__right">
          <div class="cat-rec-list__recommendation__right__content">
            <BaseDataDisplayListItem :label="t('userWeb.recommendation.blueRating')">
              <BaseBlueRatingStatusBadge
                :status="recommendation.effectiveColor"
                :size="'medium'"
                :translations="module ?? undefined"
              />
            </BaseDataDisplayListItem>
            <BaseDataDisplayListItem :label="t('userWeb.recommendation.name')">
              <p>{{ recommendation.header }}</p>
            </BaseDataDisplayListItem>
            <BaseDataDisplayListItem :label="t('userWeb.recommendation.location')">
              <p>{{ recommendation.location }}</p>
            </BaseDataDisplayListItem>
            <BaseDataDisplayListItem :label="t('userWeb.recommendation.surveyorStatus')">
              <BaseStatusBadge :status="recommendation.surveyorStatus" />
            </BaseDataDisplayListItem>
            <BaseDataDisplayListItem :label="t('userWeb.recommendation.recommmendation')">
              <BaseSafeHtml :html="recommendation.text" />
            </BaseDataDisplayListItem>
            <BaseDataDisplayListItem :label="t('userWeb.recommendation.comment')">
              <BaseSafeHtml :html="displayValue(recommendation.comment)" />
            </BaseDataDisplayListItem>
            <LatestRecommendationResponse
              v-if="recommendation.responseHistory.status != SiteResponseStatus.NotSet"
              :recommendation-response="recommendation.responseHistory"
              :currency="module?.currencyCode ?? ''"
            >
              <template #filelist="{ response }">
                <ResponseFileList :module-id="moduleId" :response="response" />
              </template>
            </LatestRecommendationResponse>
          </div>
        </div>
      </div>
    </template>
  </div>

  <ExecSummaryCatRecStates
    v-if="isLoadingRecommendations || hasError"
    :is-loading-data="isLoadingRecommendations"
    :error-fetching-data="hasError"
  />
</template>

<style scoped lang="scss">
$border: 1px solid $primary-4;
$spacing: $spacing-8;

.cat-rec-list__recommendation {
  display: grid;
  gap: $spacing;
  grid-template-columns: 1fr 6fr;

  &:last-child {
    .cat-rec-list__recommendation {
      &__right {
        &__content {
          margin-bottom: 0;
          border-bottom: none;
        }
      }
    }
  }

  &__title {
    position: sticky;
    top: 0;
    font-weight: $font-medium;
    font-variant-numeric: tabular-nums;
    padding-bottom: $spacing;
  }

  &__right {
    border-left: $border;
    padding-left: $spacing;

    &__content {
      display: flex;
      flex-direction: column;
      gap: $spacing-4;
      padding-bottom: $spacing;
      margin-bottom: $spacing;
      border-bottom: $border;
    }
  }
}
</style>
