<script setup lang="ts">
import { ErrorObject } from "@vuelidate/core";
import { ref } from "vue";
import { useUniqueId } from "@/composables/useUniqueId";
import { vAutoFocus } from "@/directives/autoFocus";
import BaseInputRequired from "./BaseInputRequired.vue";
import BaseLabel from "./BaseLabel.vue";

interface Props {
  value?: string | null | undefined;
  tab?: number;
  ariaLabel?: string;
  autocomplete?: string;
  autofocus?: boolean;
  label?: string;
  placeholder?: string;
  readonly?: boolean;
  disabled?: boolean;
  errors?: ErrorObject[];
  required?: boolean;
  resize?: "vertical" | "horizontal" | "none" | "both";
  maxlength?: number;
  rows?: number;
  dataTest?: string;
}

withDefaults(defineProps<Props>(), {
  value: undefined,
  tab: undefined,
  ariaLabel: undefined,
  autocomplete: undefined,
  label: "",
  placeholder: "",
  readonly: false,
  disabled: false,
  errors: () => [],
  required: false,
  autofocus: false,
  resize: "vertical",
  maxlength: undefined,
  rows: 4,
  dataTest: "",
});

defineEmits<{
  (event: "update:value", value: string | null | undefined): void;
  (event: "change:value", value: string | null | undefined): void;
  (event: "clear"): void;
}>();

const input = ref();
const id = useUniqueId("base_textarea");

defineExpose({ input });
</script>

<template>
  <div class="base-textarea">
    <div v-if="label" class="base-textarea__label">
      <BaseLabel :for-id="id" has-spacing>
        {{ label }}
      </BaseLabel>
      <BaseInputRequired v-if="required" />
    </div>
    <div
      class="base-textarea__box"
      :class="{
        'base-textarea__box--disabled': disabled || readonly,
        'base-textarea__box--error': errors.length,
      }"
    >
      <textarea
        :id="id"
        ref="input"
        v-auto-focus="autofocus"
        class="base-textarea__input"
        :maxlength="maxlength"
        :tabindex="tab"
        :placeholder="placeholder"
        :rows="rows"
        :value="value as string"
        spellcheck="false"
        :aria-label="ariaLabel"
        :autocomplete="autocomplete"
        :readonly="readonly"
        :disabled="disabled"
        :data-test="dataTest"
        @change="$emit('change:value', ($event.target as HTMLTextAreaElement).value)"
        @input="$emit('update:value', ($event.target as HTMLTextAreaElement).value)"
      />
    </div>

    <div v-if="errors.length">
      <p v-for="({ $message }, i) in errors" :key="i" class="base-textarea__error">
        {{ $message }}
      </p>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "./styles";

.base-textarea {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow-x: hidden;

  &__label {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  &__box {
    position: relative;
    display: flex;
    @include input;

    &--disabled {
      @include input-disabled;
    }

    &--error {
      @include input-error;
    }
  }

  &__input {
    width: 100%;
    background: inherit;
    padding: $spacing-3;
    border: none;
    color: inherit;
    border-radius: $rounded-base;
    resize: v-bind(resize);
  }

  &__error {
    color: $error-4;
    line-height: $leading-normal;
    margin-top: $spacing-1;
  }
}
</style>
