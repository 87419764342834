<script setup lang="ts">
import { ref } from "vue";
import { useEventListener } from "@/composables/useEventListener";
import { vFocusTrap } from "@/directives/focusTrap";
import UWDrawerBackdrop from "./UWDrawerBackdrop.vue";
import UWDrawerExtension, { type Props as Extension } from "./UWDrawerExtension.vue";
import UWDrawerHeader from "./UWDrawerHeader.vue";

enum DrawerState {
  Expanded = "expanded",
  Collapsed = "collapsed",
}

interface Props {
  drawerTitle: string;
  subTitle?: string;
  extension?: Extension;
  hasBackdrop?: boolean;
  size?: "small" | "medium" | "large";
}

const props = withDefaults(defineProps<Props>(), {
  subTitle: "",
  extension: undefined,
  hasBackdrop: true,
  size: "medium",
});

const emit = defineEmits<{
  closeDrawer: [void];
  closeExtension: [void];
}>();

const drawerState = ref(DrawerState.Expanded);

const onCloseDrawer = () => {
  // Close possible open extension
  emit("closeExtension");

  // Update drawer state to trigger animation
  drawerState.value =
    drawerState.value === DrawerState.Expanded ? DrawerState.Collapsed : DrawerState.Expanded;

  setTimeout(() => {
    emit("closeDrawer");
  }, 150); // Must be slightly faster than animation duration to prevent flicker
};

useEventListener(document, "keyup", (event: KeyboardEvent) => {
  if (event.key === "Escape") onCloseDrawer();
});
</script>

<template>
  <div
    v-focus-trap
    class="base-drawer"
    role="dialog"
    aria-modal="true"
    :aria-labelledby="drawerTitle"
    :aria-expanded="drawerState === DrawerState.Expanded"
  >
    <UWDrawerBackdrop v-if="props.hasBackdrop" :drawer-state="drawerState" @click="onCloseDrawer" />

    <Transition name="slide">
      <UWDrawerExtension
        v-if="$slots.extension"
        :title="extension.title"
        :size="extension.size"
        @close="$emit('closeExtension')"
      >
        <template #extension>
          <slot name="extension" />
        </template>
      </UWDrawerExtension>
    </Transition>

    <div
      class="base-drawer__panel"
      :class="`base-drawer__panel--${drawerState} base-drawer__panel--${props.size}`"
    >
      <UWDrawerHeader :title="drawerTitle" :sub-title="subTitle" @close="onCloseDrawer" />

      <div class="base-drawer__panel-content">
        <slot />
      </div>

      <div v-if="$slots['footer-left'] || $slots['footer-right']" class="base-drawer__footer">
        <div v-if="$slots['footer-left']" class="base-drawer__footer__side">
          <slot name="footer-left" v-bind="{ onCloseDrawer }" />
        </div>
        <div v-if="$slots['footer-right']" class="base-drawer__footer__side">
          <slot name="footer-right" v-bind="{ onCloseDrawer }" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.base-drawer {
  position: fixed;
  inset: 0;
  margin-top: $header-height;
  display: flex;
  justify-content: flex-end;
  z-index: $z-10;

  &__panel {
    display: flex;
    flex-direction: column;
    background: $primary-1;
    font-size: $text-sm;
    box-shadow: $shadow-xl;
    z-index: $z-10;

    &--expanded {
      animation: slideIn $duration-200 ease-in-out;
    }
    &--collapsed {
      animation: slideOut $duration-200 ease-in-out;
    }
    &--small {
      width: min(90%, 30rem);
    }
    &--medium {
      width: min(90%, 50rem);
    }
    &--large {
      width: min(90%, 70rem);
    }

    &-content {
      display: flex;
      flex-direction: column;
      padding: $spacing-6;
      overflow: auto;
      height: 100%;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: $spacing-4;
    margin-top: auto;
    padding: $spacing-4 $spacing-6;
    border-top: 1px solid $primary-5;

    &__side {
      display: flex;
      align-items: center;
      gap: $spacing-4;
    }
  }
}
</style>
