<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";
import { languages } from "@/helpers/options";
import { toBase64 } from "@/helpers/toBase64";
import { libraryEvents } from "@/services/library";
import { getModule } from "@/services/modules";
import { notify } from "@/stores/notifications";
import { Language, ModuleDto, UploadLibraryFile } from "@/types/_generated/api";
import BaseCard from "@/components/base/BaseCard/BaseCard.vue";
import BaseCheckbox from "@/components/base/BaseCheckbox/BaseCheckbox.vue";
import BaseDrawer from "@/components/base/BaseDrawer/BaseDrawer.vue";
import BaseFileUpload, { FileUpload } from "@/components/base/BaseFileUpload/BaseFileUpload.vue";
import { FileFormat } from "@/components/base/BaseFileUpload/FileFormat";
import BasePill from "@/components/base/BasePills/BasePill.vue";
import BasePillDeleteButton from "@/components/base/BasePills/BasePillDeleteButton.vue";
import BasePillsInput from "@/components/base/BasePills/BasePillsInput.vue";
import BaseOption from "@/components/base/BaseSelect/BaseOption.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";

const props = defineProps<{ moduleId: number }>();

defineEmits<{ (event: "close"): void }>();

const { t } = useI18n({ useScope: "global" });

const module = ref<ModuleDto>();
const language = ref<Language>(Language.English);
const isVisibleInUserWeb = ref<boolean>(false);
const tags = ref<string[]>([]);

onBeforeMount(async () => {
  module.value = (await getModule(props.moduleId)) ?? undefined;
  language.value = module.value?.language ?? Language.English;
});

const getBody = async (files: FileUpload[]): Promise<Record<keyof UploadLibraryFile, unknown>> => ({
  file: {
    fileName: files[0].file.name,
    file: await toBase64(files[0].file),
    fileSize: files[0].file.size,
  },
  isVisibleInUserWeb: isVisibleInUserWeb.value,
  language: language.value,
  tags: tags.value,
  moduleId: props.moduleId,
});
</script>

<template>
  <BaseDrawer
    class="module-library-drawer"
    :title="t('common.actions.uploadFiles')"
    :width="'30'"
    @close="$emit('close')"
  >
    <div class="module-library-drawer__content">
      <BaseCard v-if="module">
        <div class="module-library-drawer__upload">
          <BaseSelect
            class="module-library-drawer__upload__language"
            :label="t('common.language')"
            :value="language"
            @change="language = $event as Language"
          >
            <BaseOption v-for="option in languages" :key="option.value" :value="option.value">
              {{ option.title }}
            </BaseOption>
          </BaseSelect>

          <span class="module-library-drawer__upload__visible">
            <BaseCheckbox
              :label="t('library.fileUpload.isVisibleInUserWeb')"
              :checked="isVisibleInUserWeb"
              @change="isVisibleInUserWeb = !isVisibleInUserWeb"
            />
          </span>

          <BasePillsInput
            class="module-library-drawer__upload__tags"
            :add-on-space="false"
            :label="t('library.fileUpload.tags')"
            :values="tags"
            :maxlength="50"
            @add="tags = [...tags, $event]"
          >
            <template #pill="{ value, index }">
              <BasePill :key="index" :label="value">
                <template #post>
                  <BasePillDeleteButton @click="tags = tags.filter((_, i) => i !== index)" />
                </template>
              </BasePill>
            </template>
          </BasePillsInput>

          <BaseFileUpload
            vertical
            class="module-library-drawer__upload__upload"
            :max-files="1"
            :file-type="FileFormat.Document"
            :url="`/modules/${moduleId}/library-files`"
            :body="getBody"
            @files-uploaded="
              () => {
                notify.success(t('library.fileUpload.file'), t('common.uploaded').toLowerCase());
                libraryEvents.post({ action: 'create' });
              }
            "
          />
        </div>
      </BaseCard>
    </div>
  </BaseDrawer>
</template>

<style scoped lang="scss">
.module-library-drawer {
  &__content {
    padding: $spacing-6;
  }
  &__upload {
    display: grid;
    gap: $spacing-4;
    align-items: end;
    grid-template-columns: 1fr auto;
    grid-template-areas:
      "language visible"
      "tags tags"
      "upload upload";

    &__language {
      grid-area: language;
    }
    &__visible {
      grid-area: visible;
      padding-bottom: $spacing-3;
    }
    &__tags {
      grid-area: tags;
    }
    &__upload {
      grid-area: upload;
    }
  }
}
</style>
