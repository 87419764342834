<script setup lang="ts">
import { computed, onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useGetClient } from "@/composables/services/useGetClient";
import { useUnsavedChanges } from "@/composables/useUnsavedChanges";
import { useVuelidate } from "@/composables/useVuelidateWithFocusError";
import { intersectAssign } from "@/helpers/intersectAssign";
import { createClient, updateClient } from "@/services/clients";
import { CreateClient, Status, UpdateClient } from "@/types/_generated/api";
import { required } from "@/validation/i18n-validators";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseDrawer from "@/components/base/BaseDrawer/BaseDrawer.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";
import ClientStatusSelect from "./ClientStatusSelect.vue";

const { t } = useI18n({ useScope: "global" });

const props = defineProps<{ clientId: number | null }>();

const emits = defineEmits<{ (event: "close"): void }>();
const { isLoadingClient, getClient } = useGetClient();

const { setCompareBase, discardUnsavedChanges } = useUnsavedChanges();

class EditClientForm {
  name = "";
  willisOffice = "";
  willisCountryOrUnit = "";
  website = "";
  status = Status.UnderConstruction;
  clientId?: number;
}

const form = ref();

const isEdit = computed(() => !!props.clientId);

const validations = {
  name: { required },
  willisCountryOrUnit: { required },
  willisOffice: { required },
  status: { required },
};

const { v$, focusError, addRef } = useVuelidate(validations, form, {
  $autoDirty: true,
});

onBeforeMount(async () => {
  form.value = form.value = new EditClientForm();

  if (props.clientId) {
    const client = await getClient(props.clientId);
    form.value = intersectAssign(new EditClientForm(), client);
  }

  setCompareBase(form);

  v$.value.$reset();
});

const saveClient = async (form: EditClientForm) => {
  v$.value.$validate();

  if (v$.value.$invalid) {
    focusError();
    return;
  }

  const clientId = await (isEdit.value
    ? updateClient(form as UpdateClient)
    : createClient(form as CreateClient));

  if (clientId !== null) {
    setCompareBase(form);
  }

  if (!isEdit.value) {
    emits("close");
  }
};

const close = async () => {
  if (await discardUnsavedChanges(form)) {
    emits("close");
  }
};
</script>

<template>
  <BaseDrawer
    :title="form?.clientId ? t('clients.edit') : t('clients.create')"
    :subtitle="form?.name"
    data-test="client-edit-drawer"
    :is-loading="isLoadingClient"
    width="30"
    @close="close"
  >
    <section class="client-edit-drawer">
      <BaseTextField
        :ref="(el) => addRef(el, 'name')"
        v-model:value="form.name"
        :label="t('common.name')"
        :errors="v$.name.$errors"
        required
        data-test="client-edit-name"
      />
      <BaseTextField
        :ref="(el) => addRef(el, 'willisCountryOrUnit')"
        v-model:value="form.willisCountryOrUnit"
        :label="t('clients.country')"
        :errors="v$.willisCountryOrUnit.$errors"
        required
        data-test="client-edit-country"
      />
      <BaseTextField
        :ref="(el) => addRef(el, 'website')"
        v-model:value="form.website"
        :label="t('clients.website')"
        data-test="client-edit-website"
      />
      <BaseTextField
        :ref="(el) => addRef(el, 'willisOffice')"
        v-model:value="form.willisOffice"
        :label="t('clients.office')"
        :errors="v$.willisOffice.$errors"
        required
        data-test="client-edit-office"
      />

      <ClientStatusSelect
        v-if="form?.clientId"
        v-model:value="form.status"
        data-test="client-edit-status"
      />
    </section>

    <template #footer-left>
      <BaseButton data-test="client-edit-save-button" @click="() => saveClient(form)">
        {{ isEdit ? t("common.actions.save") : t("common.actions.create") }}
      </BaseButton>

      <BaseButton variant="outlined" @click="close">
        {{ t("common.actions.cancel") }}
      </BaseButton>
    </template>
  </BaseDrawer>
</template>

<style scoped lang="scss">
.client-edit-drawer {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;
  padding: $spacing-6;
}
</style>
