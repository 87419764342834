<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import type { TableLegend } from "@/components/shared/BlueRatingTable/types/types";
import { useDownloadBlob } from "@/composables/useDownloadBlob";
import {
  SearchExecSummaryCategoryExcel,
  type ExecutiveReportCategoryDto,
  type ExecutiveReportSurveyDto,
  type ModuleDto,
} from "@/types/_generated/api";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import UWDrawer from "@/components/base/UserWeb/Drawer/UWDrawer.vue";
import BlueRatingTableDynamicHeight from "@/components/shared/BlueRatingTable/BlueRatingTableDynamicHeight.vue";
import BlueRatingTableLegend from "@/components/shared/BlueRatingTable/BlueRatingTableLegend.vue";
import BlueRatingTableRotatedHeader from "@/components/shared/BlueRatingTable/BlueRatingTableRotatedHeader.vue";
import type { SurveyGroup } from "../../types/SurveyGroup";
import { useCategoryTableRows } from "../../composables/useCategoryTableRows";
import { useTableRows } from "../../composables/useTableRows";
import { FilterColumn } from "../../constants/FilterColumn";
import { mergeFilters } from "../../helpers/mergeFilters";
import { ClientFilter, ServerFilter } from "../../types/FilterTypes";
import ExecSummaryTableGroupRow from "../ExecSummaryTable/ExecSummaryTableCells/ExecSummaryTableGroupRow.vue";
import ExecSummaryCatDrilldownRow from "./ExecSummaryCatDrilldownRow.vue";

const props = defineProps<{
  moduleId: number;
  categoryIndex: number;
  clientFilter: ClientFilter;
  serverFilter: ServerFilter;
  groupedSurveys: SurveyGroup[];
  tableLegend: TableLegend | null;
  category: ExecutiveReportCategoryDto;
  surveys: ExecutiveReportSurveyDto[];
  abeyanceShort: ModuleDto["abeyanceShort"];
}>();

defineEmits<{
  closeDrawer: [void];
}>();

const { t } = useI18n({ useScope: "global" });
const { blobDownload } = useDownloadBlob();

const categoryTitle = computed(() => {
  return `${props.category.position}. ${props.category.categoryName}`;
});

const showPercentage = computed(() =>
  props.clientFilter.selectedColumns.has(FilterColumn.Percentage),
);

const showScore = computed(() => props.clientFilter.selectedColumns.has(FilterColumn.Score));

const { tableRows } = useTableRows(
  computed(() => Boolean(props.serverFilter.groupBySiteDefinition)),
  computed(() => props.groupedSurveys),
  computed(() => props.surveys),
);

const { sortedTableRows } = useCategoryTableRows(
  tableRows,
  props.categoryIndex,
  props.serverFilter.sortBy,
  !!props.serverFilter.groupBySiteDefinition,
);

const downloadExecSummaryExcel = () => {
  const { categoryId } = props.category;

  const url = `/v1/modules/${props.moduleId}/reports/executive-summary/excel/${categoryId}`;

  const body = mergeFilters(props.moduleId, props.clientFilter, props.serverFilter);

  blobDownload.post<SearchExecSummaryCategoryExcel>(url, {
    categoryId,
    ...body,
  });
};
</script>

<template>
  <UWDrawer :drawer-title="categoryTitle" @close-drawer="$emit('closeDrawer')">
    <div>
      <BaseButton
        variant="outlined"
        :title="t('common.actions.exportExcel')"
        @click="downloadExecSummaryExcel"
      >
        <BaseIcon size="medium" icon="excel" />
      </BaseButton>

      <BlueRatingTableDynamicHeight>
        <table>
          <thead>
            <tr>
              <th></th>
              <th></th>
              <BlueRatingTableRotatedHeader
                v-for="(question, qIndex) in category.questions"
                :key="qIndex"
              >
                {{ `${question.position}. ${question.text}` }}
              </BlueRatingTableRotatedHeader>

              <th>{{ t("userWeb.blueRatingTable.surveyType") }}</th>
              <th v-if="showScore">{{ t("userWeb.blueRatingTable.score") }}</th>
              <th v-if="showPercentage">
                {{ `${t("userWeb.blueRatingTable.score")} (%)` }}
              </th>
            </tr>
          </thead>

          <tbody>
            <template v-for="(row, index) in sortedTableRows" :key="index">
              <ExecSummaryTableGroupRow v-if="'label' in row" :label="row.label" />
              <ExecSummaryCatDrilldownRow
                v-else
                :show-percentage="showPercentage"
                :show-score="showScore"
                :category-index="categoryIndex"
                :abeyance-short="abeyanceShort"
                :survey="row.survey"
              />
            </template>
          </tbody>

          <BlueRatingTableLegend v-if="tableLegend" :legend="tableLegend" />
        </table>
      </BlueRatingTableDynamicHeight>
    </div>
  </UWDrawer>
</template>

<style scoped lang="scss">
@import "@/components/shared/BlueRatingTable/styles/styles";
</style>
