<script setup lang="ts">
import { type DeepReadonly } from "vue";
import { useI18n } from "vue-i18n";
import type {
  ExecutiveReportCategoryDto,
  ExecutiveReportLossScenarioDto,
} from "@/types/_generated/api";
import type { ClientFilter } from "@/views/UserWeb/ExecutiveSummary/types/FilterTypes";
import { FilterColumn } from "@/views/UserWeb/ExecutiveSummary/constants/FilterColumn";
import BlueRatingTableRotatedHeader from "@/components/shared/BlueRatingTable/BlueRatingTableRotatedHeader.vue";
import ExecSummaryTableHeaderLossScenarios from "./ExecSummaryTableHeaderLossScenarios.vue";

const props = defineProps<{
  clientFilter: ClientFilter;
  currencyCode: string;
  includedCategories: DeepReadonly<ExecutiveReportCategoryDto[]>;
  lossScenarioColumns: DeepReadonly<ExecutiveReportLossScenarioDto[]>;
}>();

defineEmits<{
  openDrawer: [categoryIndex: number];
}>();

const { t } = useI18n({ useScope: "global" });
</script>

<template>
  <thead>
    <ExecSummaryTableHeaderLossScenarios
      v-if="lossScenarioColumns.length"
      :selected-columns="clientFilter.selectedColumns"
      :currency-code="currencyCode"
      :category-column-count="includedCategories.length"
      :loss-scenario-columns="lossScenarioColumns"
    />

    <tr>
      <th></th>
      <BlueRatingTableRotatedHeader
        v-for="(column, index) in includedCategories"
        :key="column.position"
        clickable
        @click="() => $emit('openDrawer', index)"
      >
        {{ `${column.position}. ${column.categoryName}` }}
      </BlueRatingTableRotatedHeader>

      <th>{{ t("userWeb.blueRatingTable.surveyType") }}</th>

      <th v-if="props.clientFilter.selectedColumns.has(FilterColumn.Score)">
        {{ t("userWeb.blueRatingTable.score") }}
      </th>

      <th v-if="props.clientFilter.selectedColumns.has(FilterColumn.Percentage)">
        {{ `${t("userWeb.blueRatingTable.score")} (%)` }}
      </th>

      <template v-for="(lossScenario, index) in lossScenarioColumns" :key="lossScenario.id">
        <th type="th" :data-dark="index % 2 === 0">
          {{ t("userWeb.executiveSummary.lossScenario.pd") }}
        </th>
        <th type="th" :data-dark="index % 2 === 0">
          {{ t("userWeb.executiveSummary.lossScenario.bi") }}
        </th>
        <th type="th" :data-dark="index % 2 === 0">
          {{ t("userWeb.executiveSummary.lossScenario.pdBi") }}
        </th>
      </template>
    </tr>
  </thead>
</template>

<style scoped lang="scss">
@import "@/components/shared/BlueRatingTable/styles/styles";
</style>
