<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import type { ExecutiveReportLossScenarioDto } from "@/types/_generated/api";
import type { ClientFilter } from "@/views/UserWeb/ExecutiveSummary/types/FilterTypes";
import { FilterColumn } from "@/views/UserWeb/ExecutiveSummary/constants/FilterColumn";
import ModMultiselect from "@/components/mods/Multiselect/ModMultiselect.vue";

const { treatNotApplicableAsZero, selectedColumns, lossScenarios } = defineProps<{
  treatNotApplicableAsZero: boolean;
  selectedColumns: ClientFilter["selectedColumns"];
  lossScenarios: ExecutiveReportLossScenarioDto[];
}>();

const emit = defineEmits<{
  "update:selectedColumns": [ClientFilter["selectedColumns"]];
}>();

const { t } = useI18n({ useScope: "global" });

const columns = computed(() => {
  const staticColumns = [
    {
      label: t("userWeb.blueRatingTable.score"),
      value: FilterColumn.Score,
      disabled: treatNotApplicableAsZero,
    },
    { label: t("userWeb.blueRatingTable.percentage"), value: FilterColumn.Percentage },
  ];

  const dynamicColumns = lossScenarios.map((lossScenario) => ({
    label: `${t("userWeb.executiveSummary.lossScenario.title")}: ${lossScenario.name}`,
    value: `lossScenario-${lossScenario.id}`,
  }));

  return [...staticColumns, ...dynamicColumns];
});

const handleSelectOptions = (options: string[]) => {
  emit("update:selectedColumns", new Set(options));
};
</script>

<template>
  <ModMultiselect
    :options="columns"
    :selected-options="selectedColumns"
    @update:selected="handleSelectOptions"
  />
</template>
