<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useDialog } from "@/composables/useDialog";
import { getInitials } from "@/helpers/getInitials";
import { authorize, ExtRule } from "@/plugins/can";
import router from "@/router";
import { AdminRouteName, AppRouteName } from "@/router/RouteName";
import { ViewAuthStatus } from "@/router/ViewAuthStatus";
import { useAuthStore } from "@/stores/auth";
import { Role } from "@/types/_generated/api";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import UWDropdown, {
  type DropdownLink,
  type DropdownOptions,
} from "@/components/base/UserWeb/Dropdown/UWDropdown.vue";

const props = defineProps<{
  fullName: string;
}>();

const emit = defineEmits<{
  openUserDetails: [void];
}>();

const { t } = useI18n({ useScope: "global" });
const auth = useAuthStore();
const { openDialog } = useDialog();

const { moduleId, siteId } = router.currentRoute.value.params ?? {};

const getAdminRouteLink = (): DropdownLink => {
  let routeDetails;

  if (moduleId && siteId) {
    routeDetails = { name: AdminRouteName.Site, params: { moduleId, siteId } };
  } else if (moduleId) {
    routeDetails = { name: AdminRouteName.ModuleSites, params: { moduleId } };
  } else {
    routeDetails = { name: AdminRouteName.AdminDashboard };
  }

  return { label: t("navigation.administration"), icon: "bolt", ...routeDetails };
};

const userHasAdminAccess = computed(() => {
  const rules: ExtRule[] = ["createSurvey", "editSurvey"];
  return (
    authorize.isSuperiorOrEqualTo(Role.INT) ||
    rules.some((rule) => authorize.hasModulePermission(Number(moduleId), rule))
  );
});

const options = computed<DropdownOptions[]>(() => {
  const dropdownOptions: DropdownOptions[] = [
    {
      label: t("userMenu.userDetails"),
      icon: "person",
      action: userDetails,
    },
    {
      divider: true,
      label: t("userMenu.signOut"),
      icon: "sign-out",
      action: signOut,
    },
  ];
  if (userHasAdminAccess.value) {
    dropdownOptions.unshift(getAdminRouteLink());
  }

  return dropdownOptions;
});

const userDetails = () => {
  emit("openUserDetails");
};

const signOut = async () => {
  const result = await openDialog({
    title: t("app.signOutDialog.title"),
    description: t("app.signOutDialog.description"),
    confirmText: t("app.signOutDialog.title"),
  });

  if (result) {
    await auth.signOut();
    router.push({
      name: AppRouteName.Auth,
      query: { reason: ViewAuthStatus.SignedOut },
    });
  }
};

const initials = computed(() => getInitials(props.fullName));
</script>

<template>
  <nav class="user-web-header-more">
    <UWDropdown position="bottom-left" :options="options">
      <template #activator="{ toggleDropdown }">
        <BaseButton
          variant="outlined"
          color="light"
          :aria-label="t('navigation.more')"
          :aria-haspopup="true"
          data-test="user-web-header-more-btn"
          @click="toggleDropdown"
        >
          <BaseIcon icon="dots" />
          {{ initials }}
        </BaseButton>
      </template>
    </UWDropdown>
  </nav>
</template>
