<script setup lang="ts">
import { computed } from "vue";
import type { LossScenarioDto, SearchBlueLossValuesResponse } from "@/types/_generated/api";
import ModInfoState, {
  type Props as ModInfoStateProps,
} from "@/components/mods/InfoState/ModInfoState.vue";
import DAATBlueLossChart from "./components/DAATBlueLossChart.vue";
import { DaatClientFilter } from "./composables/useFilterClient";
import { DaatServerFilter } from "./composables/useFilterServer";

interface Props {
  moduleId: number;
  isLoading: boolean;
  currencyCode: string;
  data: SearchBlueLossValuesResponse["blueLossValues"];
  lossScenarios: LossScenarioDto[];
  hasError: boolean;
  clientFilter: DaatClientFilter;
  serverFilter: DaatServerFilter;
}

const {
  isLoading,
  currencyCode,
  data,
  hasError,
  lossScenarios,
  clientFilter,
  serverFilter,
  moduleId,
} = defineProps<Props>();

const hasData = computed(
  () => !Object.entries(data).some(([k, v]) => data[parseInt(k)].length > 0),
);

const currentInfoState = computed<ModInfoStateProps["state"] | null>(() => {
  if (hasError) {
    return "error";
  } else if (hasData.value) {
    return "empty";
  }
  return null;
});

const lossValues = computed(() =>
  Object.entries(data).map(([k, v]) => ({
    id: parseInt(k),
    name: lossScenarios.find((ls) => ls.id === parseInt(k))?.name ?? "",
    results: v,
  })),
);
</script>

<template>
  <div class="daat-loss-view">
    <ModInfoState v-if="currentInfoState && !isLoading" :state="currentInfoState" />
    <template v-else>
      <DAATBlueLossChart
        v-for="{ id, name, results } in lossValues"
        :key="id"
        :module-id="moduleId"
        :is-loading="isLoading"
        :loss-scenario-id="id"
        :loss-scenario-name="name"
        :currency-code="currencyCode"
        :data="results"
        :client-filter="clientFilter"
        :server-filter="serverFilter"
      />
    </template>
  </div>
</template>

<style scoped lang="scss">
.daat-loss-view {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;

  &__chart {
    height: 40rem;
  }
}
</style>
