<script setup lang="ts">
import useVuelidate from "@vuelidate/core";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useGetModule } from "@/composables/services/useGetModule";
import { useGetSurvey } from "@/composables/services/useGetSurvey";
import { useSurveyReport } from "@/composables/useSurveyReport";
import { formatDate } from "@/helpers/formatDate";
import { getLibraryFiles } from "@/services/library";
import {
  getSurveyEmailTemplateService,
  getSurveyReportMailsService,
  sendSurveyEmailService,
  toggleSurveyReportMailIsActiveService,
} from "@/services/surveys";
import {
  LibraryFileDto,
  ReportMailDto,
  SendSurveyReportMail,
  SurveyFileType,
  SurveyEmail,
  ReportMailLoginType,
} from "@/types/_generated/api";
import { minLength, required } from "@/validation/i18n-validators";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseCard from "@/components/base/BaseCard/BaseCard.vue";
import BaseDrawer from "@/components/base/BaseDrawer/BaseDrawer.vue";
import BaseDrawerTabs from "@/components/base/BaseDrawerTabs/BaseDrawerTabs.vue";
import BaseEditor from "@/components/base/BaseEditor/BaseEditor.vue";
import BaseInfoState from "@/components/base/BaseInfoState/BaseInfoState.vue";
import BaseLabel from "@/components/base/BaseLabel.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";
import ReportMailAddRecipient from "./components/ReportMailAddRecipient.vue";
import ReportMailAttachments from "./components/ReportMailAttachments.vue";
import ReportMailHistoryTable from "./components/ReportMailHistoryTable.vue";
import ReportMailRecipientList from "./components/ReportMailRecipientList.vue";
import {
  useSurveyReportMailTabs,
  SurveyReportMailTab,
} from "./composables/useReportMailDrawerTabs";
import { useReportMailRecipients } from "./composables/useReportMailRecipients";

const { t } = useI18n({ useScope: "global" });

const props = defineProps<{
  surveyId: number;
  moduleId: number;
}>();

const emit = defineEmits<{
  close: [void];
}>();

const defaultCodeExpirationHours = 2;
const isLoadingHistory = ref(false);
const isSendingMail = ref(false);
const files = ref<LibraryFileDto[]>([]);
const history = ref<ReportMailDto[] | null>([]);

const { survey, getSurvey, isLoadingSurvey } = useGetSurvey();
const { recipients, addRecipient, updateRecipient, deleteRecipient } = useReportMailRecipients();
const { reportFiles, getSurveyFiles, isLoadingSurveyReports } = useSurveyReport();
const { activeTab, changeTab, tabs } = useSurveyReportMailTabs();
const { module, getModule, isLoadingModule } = useGetModule();

const form = ref<SendSurveyReportMail>({
  subject: "",
  body: "",
  recipients: [],
  codeExpirationHours: defaultCodeExpirationHours,
  includeLibraryFileIds: [],
  surveyId: props.surveyId,
});

const isLoading = computed(
  () =>
    isLoadingSurvey.value ||
    isLoadingHistory.value ||
    isLoadingSurveyReports.value ||
    isLoadingModule.value ||
    isSendingMail.value,
);

const hasRecommendationReportFile = computed(() =>
  reportFiles.value.some((file) => file.type === SurveyFileType.RecommendationReport),
);

const globalv$ = useVuelidate({ $scope: "recipient" });

const v$ = useVuelidate(
  {
    subject: { required },
    body: { required },
    recipients: { required, minLength: minLength(1) },
  },
  form,
  {
    $autoDirty: true,
    $scope: "main",
  },
);

const sendMail = async () => {
  // Filter out unchecked recipients
  form.value.recipients = recipients.filter((r) => r.includeRecipient);
  v$.value.$validate();
  globalv$.value.$reset();

  if (v$.value.$invalid) return;

  isSendingMail.value = true;
  const response = await sendSurveyEmailService(form.value);
  isSendingMail.value = false;

  if (response) emit("close");
};

const setCodeExpirationHours = (value: string | number | null | undefined) => {
  const hours = Number(value);

  if (isNaN(hours)) {
    form.value.codeExpirationHours = defaultCodeExpirationHours;
  } else if (hours < 1) {
    form.value.codeExpirationHours = 1;
  } else if (hours > 9999) {
    form.value.codeExpirationHours = 9999;
  } else if (hours % 1 !== 0) {
    form.value.codeExpirationHours = Math.floor(hours);
  } else {
    form.value.codeExpirationHours = hours;
  }
};

const toggleSetActive = async (id: ReportMailDto["id"], isActive: boolean) => {
  await toggleSurveyReportMailIsActiveService(props.surveyId, id, isActive);
  const mail = history.value?.find((m) => m.id === id);
  if (mail) {
    mail.isActive = isActive;
  }
};

const isMobileRequired = computed(
  () => module.value?.reportMailLoginType === ReportMailLoginType.SMSOnly,
);

onMounted(async () => {
  isLoadingHistory.value = true;

  history.value = await getSurveyReportMailsService(props.surveyId);
  await getSurvey(props.surveyId);
  await getSurveyFiles(props.surveyId);
  await getModule(props.moduleId);

  if (!survey.value) {
    throw new Error("Survey not found");
  }

  if (survey.value?.participants.length) {
    for (const participant of survey.value.participants) {
      addRecipient({
        email: participant.email,
        mobile: participant.mobile,
        includeRecommendationResponseLink: false,
        reminderDates: [],
        continueRemindingWhenSiteResponseIsGiven: false,
        firstName: participant.firstName,
        lastName: participant.lastName,
        company: participant.company,
        title: participant.title,
      });
    }
  }

  files.value = (await getLibraryFiles(survey.value.moduleId)) ?? [];

  const template = await getSurveyEmailTemplateService(
    survey.value.siteId,
    SurveyEmail.RecommendationReport,
  );

  form.value.subject = template?.subject ?? "";
  form.value.body = template?.body ?? "";

  isLoadingHistory.value = false;
});
</script>

<template>
  <BaseDrawer
    class="report-mail-drawer"
    :title="
      t('survey.surveyReportMail.title', {
        survey: formatDate.asDateOnly(survey?.surveyDate),
      })
    "
    width="auto"
    :is-loading="isLoading"
    @close="$emit('close')"
  >
    <BaseDrawerTabs
      v-if="history?.length"
      :current-tab="activeTab"
      :tabs="tabs"
      @change="changeTab"
    />

    <div v-show="activeTab === SurveyReportMailTab.Main" class="report-mail-drawer__main">
      <BaseInfoState
        v-if="!hasRecommendationReportFile"
        :title="t('survey.surveyReportMail.empty.title')"
        :description="t('survey.surveyReportMail.empty.description')"
        variant="empty"
      />
      <template v-else>
        <div class="report-mail-drawer__main-content">
          <BaseTextField
            v-model:value="form.subject"
            :label="t('survey.preSurveyEmail.subject')"
            :errors="v$.subject.$errors"
          />

          <div>
            <BaseLabel has-spacing>{{ t("survey.surveyReportMail.body") }}</BaseLabel>
            <BaseEditor
              :custom-height="{ min: '200px' }"
              :value="form.body"
              :errors="v$.body.$errors"
              @input="($event) => (form.body = $event)"
            />
          </div>

          <BaseTextField
            :value="form.codeExpirationHours"
            type="number"
            :label="t('survey.surveyReportMail.codeExpirationHours')"
            :max="9999"
            :min="1"
            @change:value="setCodeExpirationHours"
          />

          <ReportMailAttachments
            :files="files"
            :include-library-file-ids="form.includeLibraryFileIds"
            :survey="survey"
            @add-attachment="form.includeLibraryFileIds = [...form.includeLibraryFileIds, $event]"
            @delete-attachment="
              form.includeLibraryFileIds = form.includeLibraryFileIds.filter((f) => f !== $event)
            "
          />
        </div>

        <BaseCard :title="t('survey.surveyReportMail.addRecipients')">
          <ReportMailAddRecipient
            :module-id="moduleId"
            :is-mobile-required="isMobileRequired"
            @add:recipient="addRecipient"
          />
        </BaseCard>

        <ReportMailRecipientList
          :recipients="recipients"
          :errors="v$.recipients.$errors"
          :is-mobile-required="isMobileRequired"
          @delete:recipient="deleteRecipient"
          @update:recipient="updateRecipient"
        />
      </template>
    </div>

    <div
      v-if="history?.length"
      v-show="activeTab === SurveyReportMailTab.History"
      class="report-mail-drawer__history"
    >
      <ReportMailHistoryTable
        :history="history"
        :is-loading="isLoading"
        @on-toggle-active="toggleSetActive"
      />
    </div>

    <template #footer-left>
      <BaseButton :disabled="!hasRecommendationReportFile || isLoading" @click="sendMail">
        {{ t("common.actions.send") }}
      </BaseButton>

      <BaseButton variant="outlined" @click="$emit('close')">
        {{ t("common.actions.cancel") }}
      </BaseButton>
    </template>
  </BaseDrawer>
</template>

<style scoped lang="scss">
.report-mail-drawer {
  max-width: 40vw;
  min-width: 30rem;

  &__main,
  &__history {
    padding: $spacing-6;
  }

  &__main {
    @include grid-columns(1);
    height: 100%;

    &-content {
      display: flex;
      flex-direction: column;
      gap: $spacing-4;
    }
  }
}
</style>
