<script setup lang="ts">
import { computed } from "vue";
import { vTruncationTitle } from "@/directives/truncationTitle";
import { BlueRatingCategoryDto, BlueRatingRecommendationDto } from "@/types/_generated/api";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import BaseOption from "@/components/base/BaseSelect/BaseOption.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";

const props = defineProps<{
  category: BlueRatingCategoryDto;
  surveyRec: BlueRatingRecommendationDto;
  placeholder: string;
  disabled: boolean;
}>();

const emits = defineEmits<{
  (event: "add-question" | "remove", value: number): void;
}>();

const selectedQuestions = computed(() =>
  props.category.questions
    .filter((q) => props.surveyRec.questionIds.includes(q.questionId))
    .map((q) => ({
      label: `${q.fullPosition}: ${q.text}`,
      value: q.questionId,
    })),
);

const questionOptions = computed(() =>
  props.category.questions
    .filter(
      (question) =>
        !selectedQuestions.value.some(
          (selectedQuestion) => question.questionId === selectedQuestion.value,
        ),
    )
    .map((q) => ({
      title: `${q.fullPosition}: ${q.text}`,
      value: q.questionId,
    })),
);
</script>

<template>
  <BaseSelect
    class="blue-rating-rec-question__select"
    :options="questionOptions"
    :disabled="disabled"
    @change="
      (value) => {
        emits('add-question', Number(value));
      }
    "
  >
    <BaseOption v-if="placeholder" :value="''" disabled selected>
      {{ placeholder }}
    </BaseOption>
    <BaseOption v-for="(option, index) in questionOptions" :key="index" :value="option.value">
      {{ option.title }}
    </BaseOption>
  </BaseSelect>

  <div
    v-for="(value, i) in selectedQuestions"
    :key="i"
    class="blue-rating-rec-question__list"
    :class="{ 'blue-rating-rec-question__list--even': i % 2 === 1 }"
  >
    <span v-truncation-title class="blue-rating-rec-question__list__item truncate">
      {{ value.label }}
    </span>

    <button
      v-if="!disabled"
      type="button"
      class="blue-rating-rec-question__list__delete"
      @click="() => emits('remove', value.value)"
    >
      <BaseIcon icon="trash" />
    </button>
  </div>
</template>

<style scoped lang="scss">
.blue-rating-rec-question__select {
  width: 20rem;
}

.blue-rating-rec-question__list {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-top: 0.5rem;
  justify-content: space-between;
  border-radius: $rounded-base;
  padding: $spacing-3;
  cursor: pointer;
  &:hover {
    background-color: $primary-3;
  }
  &--even {
    background-color: $primary-2;
    &:hover {
      background-color: $primary-3;
    }
  }

  &__item {
    max-width: 50rem;
  }

  &__delete {
    color: $error-4;
  }
}
</style>
