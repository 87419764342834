<script setup lang="ts" generic="T">
import { computed, onBeforeMount, ref, toRef, useSlots } from "vue";
import { useI18n } from "vue-i18n";
import { StorageSchema } from "@/composables/useStorage/StorageSchema";
import { useTableSettingStorage } from "@/composables/useStorage/useTableSettingStorage";
import { vTruncationTitle } from "@/directives/truncationTitle";
import { Sorting } from "@/helpers/sort";
import { ColumnDefinition } from "@/types/ColumnDefinition";
import BaseCheckbox from "@/components/base/BaseCheckbox/BaseCheckbox.vue";
import BaseDropdownMenu, {
  MenuOption,
} from "@/components/base/BaseDropdownMenu/BaseDropdownMenu.vue";
import BaseSkeleton from "@/components/base/BaseSkeleton.vue";
import BaseGridTableCellData from "./components/BaseGridTableCellData.vue";
import BaseGridTableCheckAllRows from "./components/BaseGridTableCheckAllRows.vue";
import BaseGridTableColumnToggle from "./components/BaseGridTableColumnToggle.vue";
import BaseGridTableEmptyState from "./components/BaseGridTableEmptyState.vue";
import BaseGridTableHeaderSort from "./components/BaseGridTableHeaderSort.vue";
import BaseGridTablePageControls from "./components/BaseGridTablePageControls.vue";
import { useSortRows } from "./composables/useSortRows";
import { useToggleHiddenColumns } from "./composables/useToggleHiddenColumns";
import { type CellSlot, type HeaderSlot } from "./types/slot-types";

const { t } = useI18n({ useScope: "global" });

const props = withDefaults(
  defineProps<{
    columns: ColumnDefinition<T>[];
    rows: T[];
    totalRowCount?: number;
    isLoading?: boolean;
    pagination?: boolean;
    storageKey?: keyof StorageSchema;
    columnToggle?: boolean;
    rowsPerPage?: number;
    checkedRows?: T[];
    customOptions?: MenuOption[];
    defaultSort?: Sorting<T>[];
    selection?: boolean;
    enableRowClick?: boolean;
    dataTest?: string;
  }>(),
  {
    columns: () => [],
    rows: () => [],
    totalRowCount: 0,
    isLoading: false,
    selection: false,
    rowsPerPage: undefined,
    checkedRows: () => [],
    pagination: true,
    columnToggle: true,
    storageKey: undefined,
    customOptions: () => [],
    defaultSort: undefined,
    enableRowClick: false,
    dataTest: "base-grid-table",
  },
);

const { settings } = useTableSettingStorage(props.storageKey);

const slots = useSlots();

const actualRowsPerPage = ref<number>(25);
const start = ref(0);
const end = ref(0);
const hoveredRowIndex = ref<number>(-1);

const { toggleColumnVisibility, visibleColumns, hiddenColumns } = useToggleHiddenColumns(
  toRef(() => props.columns),
);

const { sortRows, sortedRows, sortKey, sortOrder } = useSortRows(
  toRef(() => props.rows),
  toRef(() => props.columns),
  props.defaultSort,
);

onBeforeMount(() => {
  actualRowsPerPage.value =
    // An explicit prop values has been passed
    props.rowsPerPage || settings.value.rowsPerPage;

  // Set the pagination end value to the actual rows per page
  end.value = actualRowsPerPage.value;
});

const emits = defineEmits<{
  (event: "update:checked", value: T[]): void;
  (event: "click:row", value: T): void;
}>();

const displayRows = computed(() =>
  props.pagination ? sortedRows.value.slice(start.value, end.value) : sortedRows.value,
);

const gridColumns = computed(() => {
  let css = "";

  for (const column of visibleColumns.value) {
    if (column.maxWidth) css += ` minmax(auto, ${column.maxWidth})`;
    else if (column.autoWidth) css += " auto";
    else css += " 1fr";
  }

  if (props.selection) {
    css = `auto ${css}`;
  }

  if (slots.actions) {
    css = `${css} auto`;
  }

  return css;
});

const onPaginationListUpdate = (range: { start: number; end: number }) => {
  start.value = range.start;
  end.value = range.end;
  emits("update:checked", []);
};

const onToggleCheckbox = (checked: boolean, row: T) => {
  const filteredRows = props.checkedRows.filter((r) => r !== row);
  emits("update:checked", checked ? [...filteredRows, row] : filteredRows);
};

const handleSortRows = (column: ColumnDefinition<T>) => {
  if (column.sortable) {
    sortRows(column.key);
    // Reset pagination to first page
    onPaginationListUpdate({ start: 0, end: actualRowsPerPage.value });
  }
};
</script>

<template>
  <div class="base-grid-table" :data-test="dataTest">
    <div class="base-grid-table__controls">
      <div class="base-grid-table__controls__main">
        <slot name="controls"></slot>

        <BaseDropdownMenu
          :data-test="`${dataTest}-custom-options`"
          variant="outlined"
          :title="t('library.table.moreOptions')"
          :options="[
            {
              label: t('library.table.toggleTruncate'),
              checked: settings.truncateTableValues,
              action: () => (settings.truncateTableValues = !settings.truncateTableValues),
            },
            ...customOptions,
          ]"
        />

        <BaseGridTableColumnToggle
          v-if="columnToggle"
          :columns="columns"
          :hidden-columns="hiddenColumns"
          @toggle-column="toggleColumnVisibility"
        />
      </div>

      <div class="base-grid-table__controls__action">
        <slot name="control-actions"></slot>
      </div>
    </div>

    <div class="base-grid-table__main">
      <BaseGridTableCheckAllRows
        v-if="selection"
        :checked-rows="checkedRows"
        :rows="displayRows"
        @update:checked="$emit('update:checked', $event)"
      />
      <template v-for="(column, i) of visibleColumns" :key="i">
        <span
          v-truncation-title
          class="base-grid-table__main__header"
          :class="{
            'base-grid-table__main__header--truncated': settings.truncateTableValues,
          }"
          :data-test="`${dataTest}-header-${column.key}`"
          @click="handleSortRows(column)"
        >
          <slot :name="`header-${column.key}` as HeaderSlot<T>" :column="column">
            {{ column.title }}
          </slot>

          <BaseGridTableHeaderSort
            v-if="column.sortable && !hiddenColumns.includes(column.key)"
            :column="column"
            :sort-key="sortKey"
            :sort-order="sortOrder"
          />
        </span>
      </template>

      <span v-if="$slots.actions" class="base-grid-table__main__header"></span>

      <template v-if="!isLoading && rows.length === 0">
        <div class="base-grid-table__main__row">
          <BaseGridTableEmptyState :total-row-count="totalRowCount" />
        </div>
      </template>

      <template v-if="!isLoading && rows.length > 0">
        <div
          v-for="(row, i) of displayRows"
          :key="i"
          class="base-grid-table__main__row"
          :data-test="`row_${i}`"
          :class="{
            'base-grid-table__main__row--odd': (i + 1) % 2,
            'base-grid-table__main__row--hover': hoveredRowIndex === i,
            'base-grid-table__main__row--clickable': enableRowClick,
          }"
          @mouseover="hoveredRowIndex = i"
          @mouseout="hoveredRowIndex = -1"
          @click="enableRowClick && $emit('click:row', row)"
        >
          <div v-if="selection" class="base-grid-table__main__row__checkbox">
            <BaseCheckbox
              :id="`user-row-${i}`"
              :checked="checkedRows.includes(row)"
              @change="(checked) => onToggleCheckbox(checked, row)"
            />
          </div>

          <template v-for="(column, j) of visibleColumns" :key="j">
            <div
              class="base-grid-table__main__row__cell"
              :class="{
                'base-grid-table__main__row__cell--truncated': settings.truncateTableValues,
              }"
              :style="{
                textAlign: column.alignContent === 'right' ? 'right' : 'left',
              }"
            >
              <slot
                :name="`column-${column.key}` as CellSlot<T>"
                :cell="row[column.key]"
                :row="row"
                :index="i"
              >
                <BaseGridTableCellData :data="row[column.key]" :column="column" />
              </slot>
            </div>
          </template>
          <div
            v-if="$slots.actions"
            class="base-grid-table__main__row__cell base-grid-table__main__row__cell__actions"
          >
            <slot name="actions" :row="row" :index="i" />
          </div>
        </div>
      </template>
    </div>

    <BaseSkeleton
      v-if="isLoading"
      class="base-grid-table__skeleton"
      :set="4"
      width="100%"
      height="44px"
      mb="1rem"
    />
  </div>

  <BaseGridTablePageControls
    v-if="pagination"
    :start="start"
    :end="end"
    :rows-per-page="actualRowsPerPage"
    :total-row-count="rows.length"
    :is-loading="isLoading"
    :storage-key="storageKey"
    @on-page-navigation="onPaginationListUpdate"
    @on-rows-per-page="
      (event: number) => {
        onPaginationListUpdate({ start: 0, end: event });
        actualRowsPerPage = event;
        settings.rowsPerPage = event;
      }
    "
  />
</template>

<style scoped lang="scss">
.base-grid-table {
  display: flex;
  flex-direction: column;
  font-size: $text-sm;

  &__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $spacing-4 0;
    gap: $spacing-4;

    &__main {
      display: flex;
      align-items: center;
      gap: $spacing-2;
      justify-content: flex-start;
    }

    &__actions {
      display: flex;
      align-items: center;
      gap: $spacing-4;
      justify-content: flex-start;
    }
  }

  &__main {
    display: grid;
    grid-template-columns: v-bind("gridColumns");

    &__header,
    &__row__cell {
      white-space: nowrap;

      &--truncated {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    &__header {
      background-color: $primary-1;
      cursor: pointer;
      padding: $spacing-4;
      font-weight: $font-medium;
      border-bottom: 1px solid $primary-3;
      user-select: none;
      position: sticky;
      top: 0;
    }

    &__row {
      display: contents;

      &--clickable {
        cursor: pointer;
      }

      &--odd > &__cell,
      &__checkbox {
        background: $primary-2;
      }

      &__cell {
        display: flex;
        align-items: center;
        padding: $spacing-3 $spacing-4;
        min-width: 6ch;

        &__actions {
          display: flex;
          justify-content: flex-end;
          gap: $spacing-1;
          padding: $spacing-1;
          padding-right: $spacing-4;
        }
      }

      &__checkbox {
        grid-column: auto;
        padding: $spacing-4;
      }
    }
  }

  &__skeleton {
    margin: $spacing-4;
  }
}

.base-grid-table__main__row:hover .base-grid-table__main__row__cell {
  background: $primary-3;
}
</style>

<style lang="scss">
.base-grid-table {
  a {
    &:hover {
      color: $secondary-5;
    }
  }
}
</style>
