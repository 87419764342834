<script setup lang="ts">
import { onMounted, ref } from "vue";
import type { WidthOptions } from "./types";
import BaseSpinner from "../BaseSpinner.vue";
import BaseDrawerHeader from "./components/BaseDrawerHeader.vue";

interface Props {
  backdrop?: boolean;
  title?: string;
  subtitle?: string;
  isLoading?: boolean;
  width?: WidthOptions;
  dataTest?: string;
}

withDefaults(defineProps<Props>(), {
  backdrop: true,
  title: undefined,
  subtitle: undefined,
  isLoading: false,
  width: "auto",
  dataTest: "",
});

const showDrawer = ref(false);
const showBackdrop = ref(false);

const emits = defineEmits<{
  (event: "close"): void;
}>();

onMounted(() => {
  document.addEventListener("keyup", (event) => {
    event.key === "Escape" && emits("close");
  });

  showDrawer.value = true;

  setTimeout(() => (showBackdrop.value = true), 200);
});
</script>

<template>
  <transition name="drawer" appear>
    <aside
      v-if="showDrawer"
      class="base-drawer"
      role="dialog"
      aria-modal="true"
      :data-test="dataTest"
    >
      <transition name="fade" appear>
        <div
          v-if="showDrawer && showBackdrop"
          class="base-drawer__backdrop"
          @click="$emit('close')"
        ></div>
      </transition>

      <div :class="['base-drawer__panel', `base-drawer__panel--width-${width}`]">
        <BaseDrawerHeader
          :title="title"
          :subtitle="subtitle"
          :data-test="dataTest"
          @close-drawer="$emit('close')"
        >
          <template #title>
            <slot name="title">{{ title }}</slot>
          </template>
          <template v-if="$slots.subtitle" #subtitle>
            <slot name="subtitle">{{ subtitle }}</slot>
          </template>
        </BaseDrawerHeader>

        <div class="base-drawer__panel__content">
          <BaseSpinner v-if="isLoading" class="base-drawer__panel__content__loading" />
          <slot v-else />
        </div>

        <div
          v-if="$slots['footer-left'] || $slots['footer-right']"
          class="base-drawer__panel__footer"
        >
          <div v-if="$slots['footer-left']" class="base-drawer__panel__footer__side">
            <slot name="footer-left" />
          </div>

          <div v-if="$slots['footer-right']" class="base-drawer__panel__footer__side">
            <slot name="footer-right" />
          </div>
        </div>
      </div>
    </aside>
  </transition>
</template>

<style scoped lang="scss">
.base-drawer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  max-width: 90vw;
  line-height: $leading-tight;

  @media (max-width: $screen-lg) {
    left: 0;
    width: 100%;
    max-width: 100vw;
  }

  &__backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 2, 30, 0.5);

    @media (max-width: $screen-lg) {
      display: none;
    }
  }

  &__panel {
    display: grid;
    min-width: 40vw;
    max-width: 100%;
    height: 100%;
    background: $primary-1;
    flex-shrink: 0;
    z-index: 1;
    box-shadow:
      0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    grid-template-rows: auto 1fr auto;

    &--width-auto {
      width: auto;
    }
    &--width-100 {
      width: 100vw;
    }
    &--width-75 {
      width: 75vw;
    }
    &--width-50 {
      width: 50vw;
    }
    &--width-30 {
      min-width: max(30vw, 28rem); // 448px
      width: 30vw;
      @media (max-width: $screen-lg) {
        min-width: auto;
      }
    }

    @media (max-width: $screen-lg) {
      width: 100%;
    }

    &__content {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      overflow-y: auto;
      min-width: 28rem;
      @media (max-width: $screen-md) {
        min-width: auto;
      }

      .base-drawer__panel__content__loading {
        width: 100%;
        height: 100%;
      }
    }

    &__footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid $primary-5;
      padding: $spacing-4 $spacing-6;

      &__side {
        display: flex;
        align-items: center;
        gap: $spacing-4;
      }
    }
  }
}

.drawer-enter-active,
.drawer-leave-active {
  transition: transform 0.15s ease-in-out;
}

.drawer-leave-to,
.drawer-enter-from {
  transform: translateX(calc(100% + 28rem));
}
</style>
