import { ref } from "vue";
import { isDeepEqual } from "@/helpers/isDeepEqual";
import {
  BlueColor,
  SiteDefinitionFilter,
  SiteResponseStatus,
  SurveyorStatus,
  SurveyType,
  type RecommendationTrackingFilter,
} from "@/types/_generated/api";

export class RecTrackingFilter implements RecommendationTrackingFilter {
  surveyDateMin?: string;
  surveyDateMax?: string;
  surveyType?: SurveyType;
  excludedSiteDefinitions: SiteDefinitionFilter = {
    custom1: [],
    custom2: [],
    custom3: [],
    custom4: [],
  };
  recommendationIds?: number[] | undefined;
  categoryIds?: number[] | undefined;
  recommendationColors?: BlueColor[] = Object.values(BlueColor);
  siteResponseStatuses?: SiteResponseStatus[] = Object.values(SiteResponseStatus);
  surveyorStatuses?: SurveyorStatus[] = [
    SurveyorStatus.NotSet,
    SurveyorStatus.NoProgress,
    SurveyorStatus.UnderReview,
    SurveyorStatus.Rejected,
    SurveyorStatus.InProgress,
  ];
  rcbaRatioMin?: number;
  rcbaRatioMax?: number;
  rcbaCostToCompleteMin?: number;
  rcbaCostToCompleteMax?: number;
  recommendationNumber?: string;
  recommendationHeadline?: string;
  priority?: string;
  includeRecResponseFiles?: boolean;
}

export const useFilterRecTracking = (
  onUpdateFilter: (newFilter: RecommendationTrackingFilter) => void,
) => {
  const activeFilter = ref<RecTrackingFilter>(new RecTrackingFilter());

  const updateFilter = async (newFilter: RecommendationTrackingFilter) => {
    // If the filter has not changed, do nothing
    if (isDeepEqual(activeFilter.value, newFilter)) return;

    // Fetches updated table data based on new filter settings
    onUpdateFilter(newFilter);

    // Update the filter state with the incoming new values
    activeFilter.value = newFilter;
  };

  return {
    activeFilter,
    updateFilter,
  };
};
