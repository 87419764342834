<script setup lang="ts">
import { toRef } from "vue";
import { useI18n } from "vue-i18n";
import { useDownloadBlob } from "@/composables/useDownloadBlob";
import { useDrawerState } from "@/composables/useDrawerState";
import { SurveyType, type GetBlueRatingSummaryResponse } from "@/types/_generated/api";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import BlueRatingTableToolbar from "@/components/shared/BlueRatingTable/BlueRatingTableToolbar.vue";
import SiteBlueRatingFilterDrawer from "./components/BlueRating/SiteBlueRatingFilterDrawer.vue";
import SiteBlueRatingStates from "./components/BlueRating/SiteBlueRatingStates.vue";
import SiteBlueRatingTable from "./components/BlueRating/SiteBlueRatingTable.vue";
import { useSiteBlueRatingFilter } from "./composables/useSiteBlueRatingFilter";
import { FilterColumn } from "./constants/FilterColumn";

const props = defineProps<{
  siteId: number;
  blueRatingSummary: GetBlueRatingSummaryResponse | null;
  hasLoadingError: boolean;
  isLoading: boolean;
}>();

const { t } = useI18n({ useScope: "global" });
const { showDrawer, openDrawer, closeDrawer } = useDrawerState();
const { filteredData, hasNoSurveys, tableFilter, updateTableFilter } = useSiteBlueRatingFilter(
  toRef(props, "blueRatingSummary"),
);
const { blobDownload } = useDownloadBlob();

const downloadBlueRatingSummaryExcel = () => {
  const url = `/v1/sites/${props.siteId}/blue-rating-summary/excel`;

  const params = {
    type: tableFilter.surveyType === SurveyType.WEXT ? null : tableFilter.surveyType,
    showScore: tableFilter.selectedColumns.has(FilterColumn.Score),
    showScorePercent: tableFilter.selectedColumns.has(FilterColumn.Percentage),
  };

  blobDownload.get(url, params);
};
</script>

<template>
  <div class="view-site-blue-rating">
    <BlueRatingTableToolbar v-if="!hasLoadingError">
      <template #left>
        <BaseButton
          :disabled="isLoading"
          variant="outlined"
          :title="t('common.filter')"
          @click="openDrawer"
        >
          <BaseIcon size="medium" icon="filter" />
        </BaseButton>
      </template>
      <template #right>
        <BaseButton
          variant="outlined"
          :title="t('common.actions.exportExcel')"
          @click="downloadBlueRatingSummaryExcel"
        >
          <BaseIcon size="medium" icon="excel" />
        </BaseButton>
      </template>
    </BlueRatingTableToolbar>

    <SiteBlueRatingStates
      v-if="isLoading || hasLoadingError || hasNoSurveys"
      :is-loading="isLoading"
      :has-loading-error="hasLoadingError"
      :is-empty="hasNoSurveys"
    />

    <SiteBlueRatingTable
      v-if="filteredData"
      :table-filter="tableFilter"
      :blue-rating-summary="filteredData"
      @open-drawer="openDrawer"
    />

    <SiteBlueRatingFilterDrawer
      v-if="showDrawer"
      :table-filter="tableFilter"
      @close-drawer="closeDrawer"
      @apply-table-filter="updateTableFilter"
    />
  </div>
</template>

<style scoped lang="scss">
.view-site-blue-rating {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  gap: $spacing-4;
  height: 100%;
}
</style>
