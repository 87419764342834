<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import type { TrackedRecommendation } from "@/composables/services/useSearchRecommendationTracking";
import type {
  ModuleDto,
  RecommendationTrackingFilter,
  SearchRecTrackingExcel,
  SiteDto,
} from "@/types/_generated/api";
import { useDownloadBlob } from "@/composables/useDownloadBlob";
import { toCurrency } from "@/helpers/formatNumber";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import BaseLabel from "@/components/base/BaseLabel.vue";
import RecTracking from "@/components/shared/RecTracking/RecTracking.vue";
import { useRecommendationTrackingColumns } from "./composables/useSiteRecTrackingColumns";

const props = defineProps<{
  isLoading: boolean;
  site: SiteDto | null;
  activeFilter: RecommendationTrackingFilter;
  module: ModuleDto;
  moduleCurrency: string;
  trackedRecommendations: TrackedRecommendation[];
}>();

defineEmits<{
  "open-filter": [void];
  "apply-filter": [RecommendationTrackingFilter];
}>();

const { t } = useI18n({ useScope: "global" });
const { columns } = useRecommendationTrackingColumns();

const totalCost = computed(() =>
  props.trackedRecommendations.reduce(
    (total, recommendation) => total + (recommendation.siteResponseCostToComplete ?? 0),
    0,
  ),
);

const { blobDownload } = useDownloadBlob();

const handleDownload = () => {
  const url = `/v1/modules/${props.module.moduleId}/reports/recommendation-tracking/excel`;
  const body: SearchRecTrackingExcel = {
    moduleId: props.module.moduleId,
    filter: props.activeFilter,
    siteId: props.site?.siteId,
  };
  blobDownload.post(url, body);
};
</script>

<template>
  <div class="view-site-rec-tracking">
    <RecTracking
      :columns="columns"
      :is-loading="isLoading"
      :tracked-recommendations="trackedRecommendations"
      :module-color-translations="module"
      @apply-filter="$emit('apply-filter', $event)"
    >
      <template #control-actions>
        <div class="view-site-rec-tracking__top">
          <div class="view-site-rec-tracking__top__cost">
            <BaseLabel>{{ t("userWeb.siteRecommendationTracking.totalCost") }}:</BaseLabel>
            <p class="view-site-rec-tracking__top__cost__value">
              {{ toCurrency(totalCost, moduleCurrency) }}
            </p>
          </div>

          <div class="view-site-rec-tracking__top__controls">
            <BaseButton size="medium" variant="outlined" :title="t('common.actions.exportExcel')">
              <BaseIcon size="medium" icon="excel" @click="handleDownload()" />
            </BaseButton>

            <BaseButton
              variant="outlined"
              size="medium"
              :disabled="isLoading"
              @click="$emit('open-filter')"
            >
              <BaseIcon icon="filter" size="medium" />
            </BaseButton>
          </div>
        </div>
      </template>
    </RecTracking>
  </div>
</template>

<style scoped lang="scss">
.view-site-rec-tracking {
  display: flex;
  flex-direction: column;

  &__top {
    display: flex;
    align-items: center;
    gap: $spacing-8;

    &__cost {
      display: flex;
      gap: $spacing-1;
      font-size: $text-sm;

      &__value {
        color: $primary-7;
      }
    }

    &__controls {
      display: flex;
      gap: $spacing-2;
    }
  }
}
</style>
