<script setup lang="ts">
import { computed, onBeforeMount, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useDialog } from "@/composables/useDialog";
import { useRecommendationNavigation } from "@/composables/useRecommendationNavigation";
import { RecRespRouteName } from "@/router/RouteName";
import {
  RecommendationResponseDto,
  SiteResponseStatus,
  SurveyorStatus,
} from "@/types/_generated/api";
import { useRecResponseSurveyInfo } from "@/components/app/RecResponse/composables/useRecResponseSurveyInfo";
import RecResponseViewLayout from "@/components/app/RecResponse/RecResponseViewLayout.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseProgressBar from "@/components/base/BaseProgressBar.vue";
import OnlineRecReportHeader from "@/components/shared/OnlineRecReport/OnlineRecReportHeader.vue";
import OnlineRecReportHeaderSettings from "@/components/shared/OnlineRecReport/OnlineRecReportHeaderSettings.vue";
import OnlineRecReportLoading from "@/components/shared/OnlineRecReport/OnlineRecReportLoading.vue";
import OnlineRecReportMenu from "@/components/shared/OnlineRecReport/OnlineRecReportMenu.vue";
import OnlineRecReportRecommendation from "@/components/shared/OnlineRecReport/OnlineRecReportRecommendation.vue";
import OnlineRecReportRecommendationAbeyanceProperties from "@/components/shared/OnlineRecReport/OnlineRecReportRecommendationAbeyanceProperties.vue";
import OnlineRecReportRecommendationProperties from "@/components/shared/OnlineRecReport/OnlineRecReportRecommendationProperties.vue";
import OnlineRecReportRecommendationRcbaProperties from "@/components/shared/OnlineRecReport/OnlineRecReportRecommendationRcbaProperties.vue";
import LatestRecommendationResponse from "@/components/shared/RecommendationResponse/LatestRecommendationResponse.vue";
import LatestRiskManagerComment from "@/components/shared/RecommendationResponse/LatestRiskManagerComment.vue";
import ExternalResponseAndCommentTimeline from "./components/ExternalResponseAndCommentTimeline.vue";
import ExternalResponseEdit from "./components/ExternalResponseEdit.vue";
import ExternalResponseFileList from "./components/ExternalResponseFileList.vue";
import { useRecommendationsToRespond } from "./composables/useRecommendationsToRespond";
import { useRecResponseSurvey } from "./composables/useRecResponseSurvey";

const { t } = useI18n({ useScope: "global" });
const { openDialog } = useDialog();
const router = useRouter();
const { clearSurveyInfo } = useRecResponseSurveyInfo();

const {
  allowedFileTypes,
  blueRating,
  currency,
  getSurvey,
  isLoadingSurvey,
  surveyInfo,
  recommendations,
  submitRecResponses,
  translations,
} = useRecResponseSurvey();

// View Settings
const showTimeline = ref(false);
const showAbeyance = ref(false);

// Filter and sort recommendations
const {
  categories,
  recs,
  completedRecs,
  abeyanceRecs,
  respondedCount,
  respondedMaxCount,
  navRecs,
} = useRecommendationsToRespond(blueRating, recommendations, showAbeyance);

// Navigation
const {
  selectedRecommendation,
  selectRecommendation,
  indexOfSelectedRecommendation,
  selectPreviousRecommendation,
  selectNextRecommendation,
} = useRecommendationNavigation(navRecs);

onBeforeMount(() => {
  clearSurveyInfo();
});

onMounted(async () => {
  await getSurvey();
  selectedRecommendation.value = navRecs.value[0] ?? null;
});

const showRcba = computed(
  () =>
    !!(
      selectedRecommendation.value?.rcbaPropertyPrior ||
      selectedRecommendation.value?.rcbaPropertyAfter ||
      selectedRecommendation.value?.rcbaBiPrior ||
      selectedRecommendation.value?.rcbaBiAfter ||
      selectedRecommendation.value?.rcbaExtraPrior ||
      selectedRecommendation.value?.rcbaExtraAfter
    ),
);

const createResponse = (response: RecommendationResponseDto) => {
  if (selectedRecommendation.value) {
    selectedRecommendation.value.responseHistory.history.unshift(response);
  }
};

const updateResponse = (response: RecommendationResponseDto) => {
  if (selectedRecommendation.value) {
    const index = selectedRecommendation.value.responseHistory.history.findIndex(
      (r) => r.id === response.id,
    );
    if (index !== -1) {
      selectedRecommendation.value.responseHistory.history[index] = response;
    }
  }
};

const submitRecommendationResponse = async () => {
  const allLocked = navRecs.value.every((r) => r.responseHistory.history[0]?.locked ?? false);
  const allSet = navRecs.value.every(
    (r) =>
      (r.responseHistory.history[0]?.status ?? SiteResponseStatus.NotSet) !==
      SiteResponseStatus.NotSet,
  );

  if (!allLocked && !allSet) {
    return;
  }

  await submitRecResponses();

  const goBack = await openDialog({
    title: t("recommendationResponse.responseSubmitted"),
    description: t("recommendationResponse.goToSummary"),
    confirmText: t("recommendationResponse.actions.go"),
    cancelText: t("recommendationResponse.actions.stay"),
  });

  if (goBack) {
    router.push({ name: RecRespRouteName.Summary });
  }
};
</script>

<template>
  <OnlineRecReportLoading v-if="isLoadingSurvey" />

  <RecResponseViewLayout v-if="!isLoadingSurvey" class="view-rec-resp-respond">
    <OnlineRecReportHeader
      v-if="blueRating && !isLoadingSurvey"
      class="view-rec-resp-respond__header"
      :module-name="surveyInfo.moduleName"
      :site-name="surveyInfo.siteName"
      :survey-date="surveyInfo.surveyDate"
    >
      <template #default>{{ t("recResponse.recResponse") }}</template>
      <template #settings>
        <OnlineRecReportHeaderSettings
          :abeyance-value="showAbeyance"
          :abeyance-translation="translations.abeyanceTranslation"
          :timeline-value="showTimeline"
          :show-abeyance="abeyanceRecs.length > 0"
          show-timeline
          @update:abeyance="showAbeyance = $event"
          @update:timeline="showTimeline = $event"
        />
      </template>
    </OnlineRecReportHeader>

    <div class="view-rec-resp-respond__progress">
      <span
        v-if="respondedCount < respondedMaxCount"
        class="view-rec-resp-respond__progress__title"
      >
        {{
          t("recommendationResponse.progress", {
            count: respondedCount,
            max: respondedMaxCount,
          })
        }}
      </span>

      <BaseProgressBar
        v-if="respondedCount < respondedMaxCount"
        :primary-progress="respondedCount"
        :max="respondedMaxCount"
      />

      <transition name="bounce" appear>
        <BaseButton
          v-if="respondedCount === respondedMaxCount"
          class="view-rec-resp-respond__progress__submit"
          @click="submitRecommendationResponse"
        >
          {{ t("recommendationResponse.submitRecommendationResponse") }}
        </BaseButton>
      </transition>

      <div class="view-rec-resp-respond__progress__buttons">
        <BaseButton
          variant="outlined"
          :disabled="indexOfSelectedRecommendation <= 0"
          @click="selectPreviousRecommendation"
        >
          {{ t("common.actions.previous") }}
        </BaseButton>

        <BaseButton
          variant="outlined"
          :disabled="indexOfSelectedRecommendation >= navRecs.length - 1"
          @click="selectNextRecommendation"
        >
          {{ t("common.actions.next") }}
        </BaseButton>
      </div>
    </div>

    <div class="view-rec-resp-respond__nav">
      <h2 v-if="completedRecs.length" class="view-rec-resp-respond__nav__title">
        {{ t("recommendationResponse.completedRecommendations") }}
      </h2>
      <OnlineRecReportMenu
        v-if="completedRecs.length"
        status="response"
        :recommendations="completedRecs"
        :selected="selectedRecommendation ?? undefined"
        @select="selectRecommendation"
      />

      <h2 class="view-rec-resp-respond__nav__title">
        {{ t("recommendationResponse.recommendations") }}
      </h2>
      <OnlineRecReportMenu
        status="response"
        :recommendations="recs"
        :selected="selectedRecommendation ?? undefined"
        @select="selectRecommendation"
      />

      <h2 v-if="showAbeyance" class="view-rec-resp-respond__nav__title">
        {{
          t("recommendationResponse.recommendationsWithStatus", {
            status: translations.abeyanceTranslation ?? t("common.statuses.Abeyance"),
          })
        }}
      </h2>
      <OnlineRecReportMenu
        v-if="showAbeyance"
        status="response"
        :recommendations="abeyanceRecs"
        :selected="selectedRecommendation ?? undefined"
        @select="selectRecommendation"
      />
    </div>

    <div class="view-rec-resp-respond__content">
      <template v-for="recommendation in navRecs" :key="`rec_${recommendation.recommendationId}`">
        <div
          :class="{
            'view-rec-resp-respond__content__recommendation': true,
            'view-rec-resp-respond__content__recommendation--current':
              selectedRecommendation === recommendation,
          }"
        >
          <OnlineRecReportRecommendation
            hide-status
            :recommendation="recommendation"
            :abeyance-translation="translations.abeyanceTranslation"
          >
            <template #properties>
              <OnlineRecReportRecommendationAbeyanceProperties
                v-if="recommendation.surveyorStatus === SurveyorStatus.Abeyance"
                :recommendation="recommendation"
                :abeyance-date-translation="
                  t('blueRating.recommendation.properties.translationAbeyanceDate', {
                    status: translations.abeyanceTranslation,
                  })
                "
                :abeyance-comment-translation="
                  t('blueRating.recommendation.properties.translationAbeyanceComment', {
                    status: translations.abeyanceTranslation,
                  })
                "
              />
              <OnlineRecReportRecommendationProperties
                :recommendation="recommendation"
                :categories="categories"
                :module-color-translations="translations"
              />
            </template>

            <template v-if="showRcba" #rcba>
              <OnlineRecReportRecommendationRcbaProperties :rcba="recommendation" />
            </template>

            <template
              v-if="!showTimeline && recommendation.responseHistory.history.length"
              #recommendation-response
            >
              <LatestRecommendationResponse
                :currency="currency"
                :recommendation-response="recommendation.responseHistory"
                :title="t('recommendationResponse.responseSummary')"
              >
                <template #filelist="{ response }">
                  <ExternalResponseFileList :response="response" />
                </template>
              </LatestRecommendationResponse>
            </template>

            <template
              v-if="!showTimeline && recommendation.riskManagerCommentHistory.length"
              #risk-manager-comment
            >
              <LatestRiskManagerComment
                :comments="recommendation.riskManagerCommentHistory"
                :title="t('recommendationResponse.commentSummary')"
              />
            </template>

            <template v-if="showTimeline" #response-timeline>
              <ExternalResponseAndCommentTimeline
                :currency="currency"
                :response-history="recommendation.responseHistory.history"
                :risk-manager-comment-history="recommendation.riskManagerCommentHistory"
                :title="t('recommendationResponse.responseHistory')"
              />
            </template>

            <template #comments>
              <ExternalResponseEdit
                class="view-rec-resp-respond__content__recommendation__edit-response"
                :currency="currency"
                :recommendation-id="recommendation.recommendationId"
                :response-history="recommendation.responseHistory.history"
                :allowed-file-types="allowedFileTypes"
                @update:response="updateResponse"
                @create:response="createResponse"
              />
            </template>
          </OnlineRecReportRecommendation>
        </div>
      </template>
    </div>
  </RecResponseViewLayout>
</template>

<style scoped lang="scss">
.view-rec-resp-respond {
  $nav-width: max(20rem, 30vw);

  display: grid;
  grid-template-areas:
    "header header"
    "progress content"
    "nav content";
  grid-template-columns: $nav-width 1fr;
  grid-template-rows: auto auto 1fr;
  gap: $spacing-4 $spacing-8;
  height: $view-height;
  padding-top: 0;

  &__header {
    grid-area: header;
    // override OnlineRecReportHeader margin
    margin-bottom: 0;
  }

  &__progress {
    max-width: $nav-width;

    &__title {
      font-size: $text-sm;
    }
    &__submit {
      width: 100%;
      transition: transform $duration-300 cubic-bezier(0.47, 1.64, 0.41, 0.8);
    }
    &__buttons {
      margin-top: $spacing-4;
      display: flex;
      gap: $spacing-2;
      justify-content: space-between;
    }

    @media print {
      display: none;
    }
  }

  &__nav {
    grid-area: nav;

    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow: auto;

    gap: $spacing-4;

    &__title {
      font-weight: $font-semibold;
      margin-top: $spacing-4;
    }
  }

  &__content {
    grid-area: content;

    max-height: 100%;
    overflow: auto;

    &__recommendation {
      max-width: min(62rem, 60vw);
      padding-bottom: $spacing-4;
      display: none;
      &--current {
        display: block;
      }
    }
  }

  @media print {
    display: block;
    overflow: visible;
    padding: 0;
    height: auto;

    &__header {
      margin-bottom: $spacing-8;
    }

    &__nav {
      display: none;
    }

    &__content {
      width: 100%;
      max-height: auto;
      overflow-y: visible;

      &__recommendation {
        display: block;
        max-width: 100%;
        width: 100%;

        &__edit-response {
          display: none;
        }
      }
    }
  }
}

.bounce-enter-to {
  transform: scale(1.2);
}
.bounce-leave-active {
  display: none;
}
</style>
