<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { vTruncationTitle } from "@/directives/truncationTitle";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";

defineProps<{
  title?: string;
  subtitle?: string;
  dataTest?: string;
}>();

defineEmits<{
  closeDrawer: [void];
}>();

const { t } = useI18n({ useScope: "global" });
</script>

<template>
  <div class="base-drawer-header">
    <div v-truncation-title class="base-drawer-header__titles">
      <p class="base-drawer-header__titles__title">
        <slot name="title">{{ title }}</slot>
      </p>
      <div v-if="subtitle || $slots.subtitle">
        <slot name="subtitle">
          <p class="base-drawer-header__titles__subtitle">
            {{ subtitle }}
          </p>
        </slot>
      </div>
    </div>

    <BaseButton
      variant="text"
      :data-test="`${dataTest}-close`"
      :aria-label="t('common.actions.close')"
      @click="$emit('closeDrawer')"
    >
      <BaseIcon icon="close" />
    </BaseButton>
  </div>
</template>

<style scoped lang="scss">
.base-drawer-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $spacing-4 $spacing-6;
  border-bottom: 1px solid $primary-5;
  min-width: 0;

  &__titles {
    display: flex;
    flex-direction: column;
    gap: $spacing-2;

    &__title {
      font-size: $text-base;
      font-weight: $font-semibold;
    }

    &__subtitle {
      font-size: $text-sm;
    }
  }
}
</style>
