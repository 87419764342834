<script setup lang="ts">
import { ref } from "vue";
import type { TrackedRecommendation } from "@/composables/services/useSearchRecommendationTracking";
import type {
  RecommendationTrackingFilter,
  BlueRatingRecommendationDto,
} from "@/types/_generated/api";
import { useGetRecommendation } from "@/composables/services/useGetRecommendation";
import { useDrawerState } from "@/composables/useDrawerState";
import { ColumnDefinition } from "@/types/ColumnDefinition";
import { ModuleColorTranslations } from "@/types/ModuleTranslations";
import RecTrackingTable from "./components/Table/RecTrackingTable.vue";
import RecTrackingTableDrawer from "./components/Table/RecTrackingTableDrawer.vue";

const props = defineProps<{
  isLoading: boolean;
  columns: ColumnDefinition<TrackedRecommendation>[];
  trackedRecommendations: TrackedRecommendation[];
  moduleColorTranslations: ModuleColorTranslations;
  preLoadedRecommendations?: BlueRatingRecommendationDto[];
}>();

defineEmits<{
  applyFilter: [RecommendationTrackingFilter];
}>();

const selectedTrackedRecommendation = ref<TrackedRecommendation | null>();

const {
  closeDrawer: closeRecommendationDrawer,
  openDrawer: openRecommendationDrawer,
  showDrawer: showRecommendationDrawer,
} = useDrawerState<BlueRatingRecommendationDto>();

const {
  loadRecommendation: getRecommendation,
  recommendation,
  isLoadingRecommendation,
} = useGetRecommendation();

const loadRecommendation = async (surveyId: number, recommendationId: number) => {
  openRecommendationDrawer();

  if (props.preLoadedRecommendations?.length) {
    const rec = props.preLoadedRecommendations.find(
      (recommendation) => recommendation.recommendationId === recommendationId,
    );
    recommendation.value = rec ?? null;
  } else {
    await getRecommendation(surveyId, recommendationId);
  }

  selectedTrackedRecommendation.value = props.trackedRecommendations.find(
    (trackedRecommendation) =>
      trackedRecommendation.surveyId === surveyId && trackedRecommendation.id === recommendationId,
  );
};
</script>

<template>
  <RecTrackingTable
    :columns="columns"
    :is-loading="isLoading"
    :tracked-recommendations="trackedRecommendations"
    :module-color-translations="moduleColorTranslations"
    @click:row="(surveyId, recommendationId) => loadRecommendation(surveyId, recommendationId)"
  >
    <template #control-actions>
      <slot name="control-actions"></slot>
    </template>
  </RecTrackingTable>

  <RecTrackingTableDrawer
    v-if="showRecommendationDrawer && selectedTrackedRecommendation"
    :module-color-translations="moduleColorTranslations"
    :is-loading="isLoading || isLoadingRecommendation"
    :tracked-recommendation="selectedTrackedRecommendation"
    :recommendation="recommendation"
    @close-drawer="closeRecommendationDrawer"
  />
</template>
