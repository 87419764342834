import { BlueColor, SiteResponseStatus } from "@/types/_generated/api";

export const BlueRatingStatusColor: Record<BlueColor, string> = {
  [BlueColor.NotSet]: "#E6E6E6",
  [BlueColor.Blue]: "#1d428e",
  [BlueColor.Green]: "#027d61",
  [BlueColor.Yellow]: "#b68601",
  [BlueColor.Red]: "#a61932",
  [BlueColor.Gray]: "#8695aa",
};

export const SiteResponseStatusColor: Record<SiteResponseStatus, string> = {
  [SiteResponseStatus.NotSet]: "#E6E6E6",
  [SiteResponseStatus.NoProgress]: "#808080",
  [SiteResponseStatus.UnderReview]: "#FFB92B",
  [SiteResponseStatus.Completed]: "#327FEF",
  [SiteResponseStatus.Rejected]: "#F6517F",
  [SiteResponseStatus.InProgress]: "#3BDCC9",
};
