import { BaseExecSummaryExcelRequest } from "@/types/_generated/api";
import { FilterColumn } from "../constants/FilterColumn";
import { ClientFilter, ServerFilter } from "../types/FilterTypes";

export const mergeFilters = (
  moduleId: number,
  clientFilter: ClientFilter,
  serverFilter: ServerFilter,
): BaseExecSummaryExcelRequest => {
  return {
    moduleId,
    filter: {
      ...serverFilter,
      surveyType: clientFilter.surveyType,
      siteIds: [],
      includeOnlyLatestSurvey: true,
    },
    showScore: clientFilter.selectedColumns.has(FilterColumn.Score),
    showScorePercent: clientFilter.selectedColumns.has(FilterColumn.Percentage),
  };
};
