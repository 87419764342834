<script setup lang="ts">
import { toRef } from "vue";
import { useI18n } from "vue-i18n";
import { useTableFilter } from "@/composables/useTableFilter";
import { vTruncationTitle } from "@/directives/truncationTitle";
import { convertHtmlToPlainText } from "@/helpers/convertHtmlToPlainText";
import { getCategoryById, getQuestionById, isSurveyPublished } from "@/helpers/survey-helpers";
import { ModuleDto, BlueRatingDto } from "@/types/_generated/api";
import { useFlattenedRecommendations } from "@/views/Admin/BlueRating/composables/useFlattenedRecommendations";
import { useRecommendationTableColumns } from "@/views/Admin/BlueRating/composables/useRecommendationTableColumns";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseFilter from "@/components/base/BaseFilter.vue";
import BaseGridTable from "@/components/base/BaseGridTable/BaseGridTable.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import BaseStatusBadge from "@/components/base/BaseStatusBadge.vue";

const { t } = useI18n({ useScope: "global" });

const props = defineProps<{
  survey: BlueRatingDto;
  module: ModuleDto;
}>();

const emit = defineEmits<{
  "edit-recommendation": [recommendationId: number];
  "create-general-recommendation": [void];
}>();

const { recommendations } = useFlattenedRecommendations(toRef(() => props.survey));

const { columns } = useRecommendationTableColumns(
  toRef(() => props.survey),
  recommendations,
  toRef(() => props.module),
);

const { result, searchPhrase, searchKey, setSearchPhrase, setSearchKey, searchKeys } =
  useTableFilter(recommendations, columns);

const openEditRecommendationDrawer = (e: { recommendationId: number }) => {
  emit("edit-recommendation", e.recommendationId);
};
</script>

<template>
  <BaseGridTable
    enable-row-click
    :columns="columns"
    :rows="result.result"
    storage-key="blueRecommendationsTable"
    data-test="blue-rating-recommendation-table"
    @click:row="openEditRecommendationDrawer"
  >
    <template #controls>
      <BaseFilter
        :search-phrase="searchPhrase"
        :search-key="searchKey"
        :search-keys="searchKeys"
        @update:search-phrase="setSearchPhrase($event)"
        @update:search-key="setSearchKey($event)"
      />
    </template>
    <template #control-actions>
      <BaseButton
        :disabled="isSurveyPublished(survey)"
        variant="outlined"
        @click="$emit('create-general-recommendation')"
      >
        {{ t("blueRating.recommendation.addGeneralRecommendation") }}
      </BaseButton>
    </template>

    <template #header-isKeyRecommendation>
      <BaseIcon :title="t('blueRating.recommendation.properties.isKeyRecommendation')" icon="key" />
    </template>

    <template #column-text="{ row }">
      <span v-truncation-title>
        {{ convertHtmlToPlainText(row.text) }}
      </span>
    </template>

    <template #column-categoryId="{ row }">
      {{ getCategoryById(survey, row.categoryId!)?.name }}
    </template>

    <template #column-questionIds="{ row: { questionIds } }">
      {{ questionIds.map((id: number) => getQuestionById(survey, id)?.fullPosition).join(", ") }}
    </template>

    <template #column-surveyorStatus="{ row }">
      <BaseStatusBadge :status="row.surveyorStatus" />
    </template>
  </BaseGridTable>
</template>
