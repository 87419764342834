<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useSearchRecommendationTracking } from "@/composables/services/useSearchRecommendationTracking";
import { RecRespRouteName } from "@/router/RouteName";
import { RecResponseRespondStatus, type RecResponseSummaryDto } from "@/types/_generated/api";
import { useRecResponseSurveyInfo } from "@/components/app/RecResponse/composables/useRecResponseSurveyInfo";
import RecResponseViewLayout from "@/components/app/RecResponse/RecResponseViewLayout.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseNavTabs, { NavTab } from "@/components/base/BaseNavTabs/BaseNavTabs.vue";
import { useRecResponseSurvey } from "../Respond/composables/useRecResponseSurvey";
import SummaryDetailedRecTracking from "./components/SummaryDetailedRecTracking.vue";
import SummaryFiles from "./components/SummaryFiles.vue";
import SummaryOverviewRecTracking from "./components/SummaryOverviewRecTracking.vue";
import { getRecResponseSummaryService } from "./services/recResponse";

const router = useRouter();
const { t } = useI18n({ useScope: "global" });

const isLoadingSummary = ref(false);
const summary = ref<RecResponseSummaryDto | null>(null);
const { submitRecResponses } = useRecResponseSurvey();
const { setSurveyInfo } = useRecResponseSurveyInfo();
const { convertToTrackedRecommendations } = useSearchRecommendationTracking();

const trackedRecs = computed(() =>
  summary.value ? convertToTrackedRecommendations(summary.value.recTracking) : [],
);

const TABS: NavTab[] = [
  {
    title: t("userWeb.recommendationTracking.overview"),
    path: RecRespRouteName.SummaryOverview,
  },
  {
    title: t("userWeb.recommendationTracking.detailed"),
    path: RecRespRouteName.SummaryDetailed,
  },
];

onMounted(async () => {
  isLoadingSummary.value = true;
  summary.value = (await getRecResponseSummaryService()) ?? null;

  if (summary.value) {
    setSurveyInfo(summary.value.surveyInfo);
  }

  isLoadingSummary.value = false;
});

const submitRecommendationResponses = async () => {
  isLoadingSummary.value = true;

  await submitRecResponses();
  summary.value = (await getRecResponseSummaryService()) ?? null;

  isLoadingSummary.value = false;
};
</script>

<template>
  <RecResponseViewLayout v-if="!isLoadingSummary && summary" class="view-rec-resp-summary">
    <SummaryFiles
      v-if="summary.report || summary.attachments"
      :report="summary.report"
      :attachments="summary.attachments"
    />

    <div v-if="summary.showResponse" class="view-rec-resp-summary__response">
      <template v-if="summary.responseStatus === RecResponseRespondStatus.NeedsResponse">
        <p class="view-rec-resp-summary__response__header">
          {{ t("recommendationResponse.responseStatus.needsResponse") }}
        </p>
        <BaseButton @click="router.push({ name: RecRespRouteName.Respond })">
          {{ t("recommendationResponse.respondToRecommendations") }}
        </BaseButton>
      </template>
      <template v-else-if="summary.responseStatus === RecResponseRespondStatus.NeedsSubmit">
        <p class="view-rec-resp-summary__response__header">
          {{ t("recommendationResponse.responseStatus.needsSubmit") }}
        </p>
        <div class="view-rec-resp-summary__response__buttons">
          <BaseButton variant="outlined" @click="router.push({ name: RecRespRouteName.Respond })">
            {{ t("recommendationResponse.viewRecommendations") }}
          </BaseButton>
          <BaseButton @click="submitRecommendationResponses">
            {{ t("recommendationResponse.submitRecommendationResponse") }}
          </BaseButton>
        </div>
      </template>
      <template v-else-if="summary.responseStatus === RecResponseRespondStatus.Submitted">
        <p class="view-rec-resp-summary__response__header">
          {{ t("recommendationResponse.responseStatus.submitted") }}
        </p>
        <BaseButton variant="outlined" @click="router.push({ name: RecRespRouteName.Respond })">
          {{ t("recommendationResponse.viewRecommendations") }}
        </BaseButton>
      </template>
    </div>

    <hr v-if="summary.showResponse" />

    <BaseNavTabs class="view-rec-resp-summary__rec-tracking" :tabs="TABS">
      <SummaryDetailedRecTracking
        v-if="router.currentRoute.value.name === RecRespRouteName.SummaryDetailed"
        :is-loading="isLoadingSummary"
        :module-color-translations="summary.moduleTranslations"
        :module-currency="summary.moduleCurrency"
        :tracked-recommendations="trackedRecs"
        :blue-rating-recommendations="summary.recommendations"
      />

      <SummaryOverviewRecTracking
        v-if="router.currentRoute.value.name === RecRespRouteName.SummaryOverview"
        :recommendation-tracking="trackedRecs"
        :module-translations="summary.moduleTranslations"
      />
    </BaseNavTabs>
  </RecResponseViewLayout>
</template>

<style scoped lang="scss">
.view-rec-resp-summary {
  display: flex;
  flex-direction: column;
  gap: $spacing-8;

  &__response {
    display: flex;
    flex-direction: column;
    gap: $spacing-4;
    justify-content: center;
    align-items: center;
    text-align: center;

    &__header {
      font-size: $text-base;
      font-weight: $font-semibold;
      margin-bottom: $spacing-2;
      white-space: nowrap;
    }

    &__buttons {
      display: flex;
      gap: $spacing-4;
    }
  }

  &__rec-tracking {
    display: block;
  }
}
</style>
