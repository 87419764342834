import { Ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { ModuleTranslations } from "@/types/ModuleTranslations";

export const useModuleTranslations = (module: Ref<ModuleTranslations>) => {
  const { t } = useI18n({ useScope: "global" });
  const { abeyanceTranslation } = module.value;

  const translations = computed(() => ({
    name: module.value.nameTranslation.length
      ? module.value.nameTranslation
      : t("blueRating.recommendation.properties.header"),
    location: module.value.locationTranslation.length
      ? module.value.locationTranslation
      : t("blueRating.recommendation.properties.location"),
    priority: module.value.priorityTranslation.length
      ? module.value.priorityTranslation
      : t("blueRating.recommendation.properties.priority"),
    abeyance: abeyanceTranslation.length
      ? abeyanceTranslation
      : t("blueRating.surveyStatus.abeyance"),
    abeyanceDate: abeyanceTranslation.length
      ? t("blueRating.recommendation.properties.translationAbeyanceDate", {
          status: abeyanceTranslation,
        })
      : t("blueRating.recommendation.properties.abeyanceDate"),
    abeyanceComment: abeyanceTranslation.length
      ? t("blueRating.recommendation.properties.translationAbeyanceComment", {
          status: abeyanceTranslation,
        })
      : t("blueRating.recommendation.properties.abeyanceComment"),
    textForBlue: module.value.textForBlue.length
      ? module.value.textForGreen
      : t("blueRating.colors.blue"),
    textForGreen: module.value.textForGreen.length
      ? module.value.textForGreen
      : t("blueRating.colors.green"),
    textForYellow: module.value.textForYellow.length
      ? module.value.textForYellow
      : t("blueRating.colors.yellow"),
    textForRed: module.value.textForRed.length
      ? module.value.textForRed
      : t("blueRating.colors.red"),
  }));

  return { moduleTranslations: translations };
};
