<script setup lang="ts">
import { computed } from "vue";
import { useSiteResponseStatusTranslations } from "@/composables/translations/useSiteResponseStatusTranslations";
import { SiteResponseStatus } from "@/types/_generated/api";
import UWOption from "@/components/base/UserWeb/Inputs/Select/UWOption.vue";
import UWSelect from "@/components/base/UserWeb/Inputs/Select/UWSelect.vue";

const props = defineProps<{
  status: SiteResponseStatus;
  hiddenStatuses?: SiteResponseStatus[];
}>();

const emit = defineEmits<{
  update: [SiteResponseStatus];
}>();

const { translations } = useSiteResponseStatusTranslations();

const options = computed(() =>
  Object.values(SiteResponseStatus)
    // remove statuses that are in the hide prop unless the current status is that status
    .filter((status) => !(props.hiddenStatuses ?? []).includes(status) || props.status === status)
    .map((status) => ({
      value: status,
      label: translations[status],
    })),
);
</script>

<template>
  <UWSelect :value="status" @change="emit('update', $event as SiteResponseStatus)">
    <UWOption v-for="option in options" :key="option.value" :value="option.value">
      {{ option.label }}
    </UWOption>
  </UWSelect>
</template>
