<script setup lang="ts">
import { computed, toRef } from "vue";
import { useI18n } from "vue-i18n";
import currencies from "@/assets/currencies.json";
import { useVuelidate } from "@/composables/useVuelidateWithFocusError";
import { ModuleDto } from "@/types/_generated/api";
import { useModuleValidation } from "@/views/Admin/Modules/composables/useModuleValidation";
import BaseCard from "@/components/base/BaseCard/BaseCard.vue";
import BaseCheckbox from "@/components/base/BaseCheckbox/BaseCheckbox.vue";
import BasePill from "@/components/base/BasePills/BasePill.vue";
import BasePillDeleteButton from "@/components/base/BasePills/BasePillDeleteButton.vue";
import BasePillsInput from "@/components/base/BasePills/BasePillsInput.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";

const { t } = useI18n({ useScope: "global" });

const props = defineProps<{
  module: ModuleDto;
}>();

const emit = defineEmits<{
  (event: "update:module", module: ModuleDto): void;
}>();

const { blueRatingValidation } = useModuleValidation();

const { v$ } = useVuelidate(
  blueRatingValidation,
  toRef(() => props.module),
  {
    $registerAs: "edit-module-blue-rating",
    $autoDirty: true,
  },
);

const setCurrency = (event: string | number | null | undefined) => {
  const currencyCode = String(event ?? "")
    .trim()
    .toUpperCase();
  emit("update:module", { ...props.module, currencyCode });
};

const remainingCharactersCount = computed(() => {
  const maxLength = v$.value?.recommendationTypes?.maxLength.$params.max;
  const currentLength = props.module.recommendationTypes.join(";").length;

  return maxLength - currentLength;
});
</script>
<template>
  <BaseCard :title="t('modules.spoeSettings')">
    <div
      class="edit-module-drawer-blue-rating__content edit-module-drawer-blue-rating__content__two-columns"
    >
      <div>
        <BaseCheckbox
          :label="t('modules.requireSPOECheck')"
          :description="t('modules.requireSPOECheckDescription')"
          :checked="module.requireSpoeCheck"
          data-test="module-blue-rating-require-spoe-check"
          @change="$emit('update:module', { ...module, requireSpoeCheck: $event })"
        />
      </div>
      <BaseCheckbox
        :label="t('modules.allowSPOECheckEXT')"
        :checked="module.allowSpoeCheckByExt"
        data-test="module-blue-rating-allow-spoe-check-ext"
        @change="$emit('update:module', { ...module, allowSpoeCheckByExt: $event })"
      />
    </div>
  </BaseCard>

  <BaseCard :title="t('modules.recommendationSettings')">
    <div class="edit-module-drawer-blue-rating__content">
      <BasePillsInput
        :add-on-space="false"
        :label="t('modules.recommendationtTypes')"
        :values="module!.recommendationTypes"
        :errors="v$?.recommendationTypes?.$errors"
        :max-length="remainingCharactersCount"
        @add="
          $emit('update:module', {
            ...module,
            recommendationTypes: [...module.recommendationTypes, $event],
          })
        "
      >
        <template #pill="{ value, index }">
          <BasePill :key="index" :label="value" data-test="module-blue-rating-rec-type">
            <template #post>
              <BasePillDeleteButton
                @click="
                  $emit('update:module', {
                    ...module,
                    recommendationTypes: module.recommendationTypes.filter((_, i) => i !== index),
                  })
                "
              />
            </template>
          </BasePill>
        </template>
      </BasePillsInput>

      <div class="edit-module-drawer-blue-rating__content__three-columns">
        <BaseCheckbox
          :label="t('modules.forceToUseOverrideCategoryColor')"
          :checked="module.forceOverrideCategoryColor"
          data-test="module-blue-rating-force-override"
          @change="
            $emit('update:module', {
              ...module,
              forceOverrideCategoryColor: $event,
            })
          "
        />

        <BaseCheckbox
          :label="t('modules.forceToUseName')"
          :checked="module.forceName"
          data-test="module-blue-rating-force-name"
          @change="$emit('update:module', { ...module, forceName: $event })"
        />

        <BaseCheckbox
          :label="t('modules.forceToUseLocation')"
          :checked="module.forceLocation"
          data-test="module-blue-rating-force-location"
          @change="$emit('update:module', { ...module, forceLocation: $event })"
        />

        <BaseCheckbox
          :label="t('modules.forceToUsePriority')"
          :checked="module.forcePriority"
          data-test="module-blue-rating-force-priority"
          @change="$emit('update:module', { ...module, forcePriority: $event })"
        />

        <BaseCheckbox
          :label="t('modules.forceToUseType')"
          :checked="module.forceType"
          data-test="module-blue-rating-force-type"
          @change="$emit('update:module', { ...module, forceType: $event })"
        />
      </div>
    </div>
  </BaseCard>

  <BaseCard :title="t('modules.translations')">
    <div class="edit-module-drawer-blue-rating__content">
      <div class="edit-module-drawer-blue-rating__content__two-columns">
        <BaseTextField
          :maxlength="1"
          :label="t('modules.abeyanceShort')"
          :value="module.abeyanceShort"
          data-test="module-blue-rating-abeyance-short"
          :errors="v$.abeyanceShort?.$errors"
          @change:value="
            $emit('update:module', {
              ...module,
              abeyanceShort: $event as string,
            })
          "
        />

        <BaseTextField
          :label="t('modules.abeyanceTranslation')"
          :description="t('modules.abeyanceTranslationDescription')"
          :value="module.abeyanceTranslation"
          data-test="module-blue-rating-abeyance-translation"
          :errors="v$.abeyanceTranslation?.$errors"
          @update:value="
            $emit('update:module', {
              ...module,
              abeyanceTranslation: $event as string,
            })
          "
        />
      </div>
      <div class="edit-module-drawer-blue-rating__content__three-columns">
        <BaseTextField
          :label="t('modules.nameTranslation')"
          :value="module.nameTranslation"
          data-test="module-blue-rating-name-translation"
          :errors="v$.nameTranslation?.$errors"
          @update:value="
            $emit('update:module', {
              ...module,
              nameTranslation: $event as string,
            })
          "
        />
        <BaseTextField
          :label="t('modules.priorityTranslation')"
          :value="module.priorityTranslation"
          data-test="module-blue-rating-priority-translation"
          :errors="v$.priorityTranslation?.$errors"
          @update:value="
            $emit('update:module', {
              ...module,
              priorityTranslation: $event as string,
            })
          "
        />
        <BaseTextField
          :label="t('modules.locationTranslation')"
          :value="module.locationTranslation"
          data-test="module-blue-rating-location-translation"
          :errors="v$.locationTranslation?.$errors"
          @update:value="
            $emit('update:module', {
              ...module,
              locationTranslation: $event as string,
            })
          "
        />
      </div>
    </div>
  </BaseCard>

  <BaseCard :title="t('modules.colorTranslations')">
    <div class="edit-module-drawer-blue-rating__content">
      <div class="edit-module-drawer-blue-rating__content__two-columns">
        <BaseTextField
          :label="t('modules.textForBlue')"
          :value="module.textForBlue"
          data-test="module-blue-rating-blue-translation"
          :errors="v$.textForBlue?.$errors"
          @update:value="
            $emit('update:module', {
              ...module,
              textForBlue: $event as string,
            })
          "
        />
        <BaseTextField
          :label="t('modules.textForGreen')"
          :value="module.textForGreen"
          data-test="module-blue-rating-green-translation"
          :errors="v$.textForGreen?.$errors"
          @update:value="
            $emit('update:module', {
              ...module,
              textForGreen: $event as string,
            })
          "
        />
        <BaseTextField
          :label="t('modules.textForYellow')"
          :value="module.textForYellow"
          data-test="module-blue-rating-yellow-translation"
          :errors="v$.textForYellow?.$errors"
          @update:value="
            $emit('update:module', {
              ...module,
              textForYellow: $event as string,
            })
          "
        />
        <BaseTextField
          :label="t('modules.textForRed')"
          :value="module.textForRed"
          data-test="module-blue-rating-red-translation"
          :errors="v$.textForRed?.$errors"
          @update:value="
            $emit('update:module', {
              ...module,
              textForRed: $event as string,
            })
          "
        />
      </div>
    </div>
  </BaseCard>

  <BaseCard :title="t('modules.other')">
    <div class="edit-module-drawer-blue-rating__content">
      <BaseTextField
        :label="t('modules.currencyCode')"
        :value="module.currencyCode"
        data-test="module-blue-rating-currency"
        :list="currencies"
        :maxlength="4"
        :errors="v$.currencyCode?.$errors"
        @update:value="setCurrency"
      />

      <div class="edit-module-drawer-blue-rating__content__two-columns">
        <BaseCheckbox
          :label="t('modules.showAForAbeyance')"
          :checked="module.showAForAbeyance"
          data-test="module-blue-rating-show-a-for-abeyance"
          @change="$emit('update:module', { ...module, showAForAbeyance: $event })"
        />

        <BaseCheckbox
          :label="t('modules.showCforALLsiteResponseCompleted')"
          :checked="module.showCForAllCompletedOnCategory"
          data-test="module-blue-rating-show-c-for-all-completed"
          @change="
            $emit('update:module', {
              ...module,
              showCForAllCompletedOnCategory: $event,
            })
          "
        />
      </div>
    </div>
  </BaseCard>
</template>

<style scoped lang="scss">
.edit-module-drawer-blue-rating__content {
  @include grid-columns(1);
  margin-bottom: $spacing-6;

  &__two-columns {
    @include grid-columns(2);
  }

  &__three-columns {
    @include grid-columns(3);
  }
}
</style>
