<script setup lang="ts">
import { computed, onBeforeMount, ref, toRef, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useBroadcastService } from "@/composables/useBroadcastService";
import useDesign from "@/composables/useDesign";
import { recommendationEvents } from "@/services/recommendations";
import { notify } from "@/stores/notifications";
import {
  ModuleDto,
  Status,
  BlueRatingDto,
  BlueRatingRecommendationDto,
  SurveyorStatus,
  RecommendationImageMetaDataDto,
} from "@/types/_generated/api";
import { useHandleRecommendationImages } from "@/views/Admin/BlueRating/composables/useHandleRecommendationImages";
import { useHandleRecommendations } from "@/views/Admin/BlueRating/composables/useHandleRecommendations";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseDrawer from "@/components/base/BaseDrawer/BaseDrawer.vue";
import BaseDrawerTabs from "@/components/base/BaseDrawerTabs/BaseDrawerTabs.vue";
import BaseStatusBadge from "@/components/base/BaseStatusBadge.vue";
import RecommendationImageList from "@/components/shared/RecommendationImageList.vue";
import LatestRecommendationResponse from "@/components/shared/RecommendationResponse/LatestRecommendationResponse.vue";
import LatestRiskManagerComment from "@/components/shared/RecommendationResponse/LatestRiskManagerComment.vue";
import ResponseAndCommentTimeline from "@/components/shared/RecommendationResponse/ResponseAndCommentTimeline.vue";
import ResponseFileList from "@/components/shared/RecommendationResponse/ResponseFileList.vue";
import {
  useBlueRatingRecommendationDrawerTabs,
  RecommendationDrawerTabs,
} from "../../composables/useBlueRatingRecommendationDrawerTabs";
import BlueRatingCostBenefit from "./BlueRatingCostBenefit.vue";
import BlueRatingRecommendationDrawerSubtitle from "./BlueRatingRecommendationDrawerSubtitle.vue";
import BlueRatingRecommendationDrawerGeneral from "./BlueRatingRecommendationGeneral.vue";

const { t } = useI18n({ useScope: "global" });
const {
  mediaQuery: { isWidthLessThan },
} = useDesign();

const props = defineProps<{
  survey: BlueRatingDto;
  recommendationId: number;
  module: ModuleDto;
}>();

const emits = defineEmits<{
  close: [void];
}>();

const { activeTab, changeTab, tabs } = useBlueRatingRecommendationDrawerTabs();

useBroadcastService(
  recommendationEvents,
  async () => await loadRecommendation(props.survey.surveyId, props.recommendationId),
  ["create", "update"],
);

const recommendation = ref<BlueRatingRecommendationDto | null>(null);

const {
  v$,
  isHandlingRecommendation,
  loadRecommendation,
  saveRecommendation,
  updateRecommendationValue,
  deleteRecommendation,
  copyToStandardRecommendation,
  discardUnsavedChanges,
} = useHandleRecommendations(
  toRef(() => props.survey),
  recommendation,
  toRef(() => props.module),
);

const { deleteRecommendationImage, reloadRecommendationImages, saveRecommendationImageCaption } =
  useHandleRecommendationImages(recommendation);

const removeRecommendation = async () => {
  await deleteRecommendation();
  emits("close");
};

const drawerWidth = computed(() =>
  isWidthLessThan("lg")
    ? "100"
    : isWidthLessThan("xl")
      ? "75"
      : isWidthLessThan("4xl")
        ? "50"
        : "30",
);

const closeDrawer = async () => {
  if (await discardUnsavedChanges(recommendation)) {
    emits("close");
  }
};

const updateRecommendationOrder = (
  event: {
    id: RecommendationImageMetaDataDto[keyof RecommendationImageMetaDataDto];
    position: number;
  }[],
) => {
  if (!recommendation.value) return;

  recommendation.value.images.forEach(
    (image) => (image.position = event.find((i) => i.id === image.fileId)!.position),
  );

  recommendation.value.images.sort((a, b) => a.position - b.position);
};

const isReadOnly = computed(() => {
  if (props.survey.status === Status.Published) {
    return true;
  }

  switch (recommendation.value?.surveyorStatus) {
    case SurveyorStatus.Completed:
    case SurveyorStatus.Rejected:
    case SurveyorStatus.Withdrawn:
      return true;
    default:
      return false;
  }
});

const hasValidationErrors = computed(() => v$.value.$errors.length > 0);

watch(
  () => recommendation.value?.surveyorStatus,
  () => {
    if (
      recommendation.value?.surveyorStatus === SurveyorStatus.Completed &&
      !recommendation.value?.completedDate
    ) {
      recommendation.value.completedDate = props.survey.surveyDate;
    }
  },
);

onBeforeMount(async () => {
  await loadRecommendation(props.survey.surveyId, props.recommendationId);
});
</script>

<template>
  <BaseDrawer
    :width="drawerWidth"
    data-test="recommendation-edit-drawer"
    class="blue-rating-recommendation"
    :is-loading="isHandlingRecommendation"
    @close="closeDrawer"
  >
    <template #title>
      {{
        recommendation?.isGeneralRecommendation
          ? t("blueRating.recommendation.editGeneralRecommendation")
          : t("blueRating.recommendation.editRecommendation")
      }}
      <span class="blue-rating-recommendation__title">
        {{ t("surveys.survey") }}
        {{ survey.surveyDate }}
        <BaseStatusBadge :status="survey.status" />
      </span>
    </template>
    <template #subtitle>
      <BlueRatingRecommendationDrawerSubtitle
        v-if="recommendation"
        :recommendation="recommendation"
        @close-drawer="closeDrawer"
      />
    </template>

    <BaseDrawerTabs
      :current-tab="activeTab"
      :tabs="tabs"
      data-test="recommendation-tabs"
      @change="changeTab"
    >
      <BlueRatingRecommendationDrawerGeneral
        v-if="recommendation && activeTab === RecommendationDrawerTabs.General"
        :disabled="isReadOnly"
        :survey="survey"
        :module="module"
        :recommendation="recommendation"
        :v$="v$"
        @update="updateRecommendationValue"
      />

      <RecommendationImageList
        v-if="recommendation && activeTab === RecommendationDrawerTabs.Images"
        :disabled="isReadOnly"
        :recommendation-id="recommendation.recommendationId"
        :url="`surveys/${survey.surveyId}/recommendations/${recommendation.recommendationId}/files`"
        :src="`/v1/surveys/${survey.surveyId}/recommendations/${recommendation.recommendationId}/images?fileId=`"
        :images="recommendation.images"
        @delete="deleteRecommendationImage"
        @files-uploaded="
          () => {
            notify.success(
              t('standardRecommendations.standardRecommendation.image'),
              t('common.uploaded').toLowerCase(),
            );
            reloadRecommendationImages();
          }
        "
        @update:caption="saveRecommendationImageCaption"
        @update-order="updateRecommendationOrder"
      />

      <BlueRatingCostBenefit
        v-if="recommendation && activeTab === RecommendationDrawerTabs.CostBenefit"
        :disabled="isReadOnly"
        :description="recommendation?.rcbaDescription"
        :rcba="recommendation"
        @update="updateRecommendationValue"
      />

      <div
        v-if="recommendation && activeTab === RecommendationDrawerTabs.SiteResponseSummary"
        class="blue-rating-recommendation__summary"
      >
        <h3>{{ t("blueRating.recommendation.recommendationResponse.title") }}</h3>
        <h3>{{ t("blueRating.recommendation.riskManagerComments") }}</h3>

        <LatestRecommendationResponse
          :currency="module.currencyCode"
          :recommendation-response="recommendation.responseHistory"
        >
          <template #filelist="{ response }">
            <ResponseFileList :module-id="module.moduleId" :response="response" />
          </template>
        </LatestRecommendationResponse>
        <LatestRiskManagerComment :comments="recommendation.riskManagerCommentHistory" />
      </div>
      <div
        v-if="recommendation && activeTab === RecommendationDrawerTabs.SiteResponseHistory"
        class="blue-rating-recommendation__history"
      >
        <ResponseAndCommentTimeline
          :currency="module.currencyCode"
          :module-id="module.moduleId"
          :response-history="recommendation.responseHistory.history"
          :risk-manager-comment-history="recommendation.riskManagerCommentHistory"
          :title="t('recommendationResponse.responseHistory')"
        />
      </div>
    </BaseDrawerTabs>

    <template #footer-left>
      <BaseButton :disabled="hasValidationErrors" @click="saveRecommendation">
        {{ t("common.actions.save") }}
      </BaseButton>

      <BaseButton
        variant="outlined"
        :disabled="hasValidationErrors"
        @click="copyToStandardRecommendation"
      >
        {{ t("blueRating.recommendation.copyToStandardRecommendation") }}
      </BaseButton>
    </template>

    <template #footer-right>
      <BaseButton color="error" variant="outlined" @click="removeRecommendation">
        {{ t("blueRating.recommendation.deleteRecommendation") }}
      </BaseButton>
    </template>
  </BaseDrawer>
</template>

<style scoped lang="scss">
.blue-rating-recommendation {
  &__title {
    margin-left: $spacing-2;
    font-weight: normal;
    font-size: $text-sm;
  }

  &__summary {
    padding: $spacing-8;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $spacing-8;
  }

  &__history {
    padding: $spacing-8;
  }
}
</style>
