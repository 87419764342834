<script setup lang="ts">
import { computed, onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useGetLastPublishedSurvey } from "@/composables/services/useGetLastPublishedSurvey";
import { useGetModuleSettings } from "@/composables/services/useGetModuleSettings";
import { useGetSite } from "@/composables/services/useGetSite";
import { useGetUsers } from "@/composables/services/useGetUsers";
import { useRequestSpoeCheck } from "@/composables/services/useRequestSpoeCheck";
import { useDialog } from "@/composables/useDialog";
import { getSurveyService } from "@/services/surveys";
import { ModuleSettingsDto, Role, Status, UserLeanDto, SurveyDto } from "@/types/_generated/api";
import AppModuleUsersSelect from "@/components/app/AppModuleUsersSelect.vue";
import BaseAlert from "@/components/base/BaseAlert.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseCard from "@/components/base/BaseCard/BaseCard.vue";
import BaseDrawer from "@/components/base/BaseDrawer/BaseDrawer.vue";
import BaseDrawerTabs from "@/components/base/BaseDrawerTabs/BaseDrawerTabs.vue";
import {
  SurveySettings,
  useInitializeSurveySettings,
} from "../../composables/useInitializeSurveySettings";
import { useSaveSurveySettings } from "../../composables/useSaveSurveySettings";
import { useSurveyEditTabs, SurveyEditTab } from "../../composables/useSurveyEditTabs";
import { useValidateSurveySettings } from "../../composables/useValidateSurveySettings";
import SurveyEditDrawerCopy from "./SurveyEditDrawerCopy.vue";
import SurveyEditDrawerDate from "./SurveyEditDrawerDate.vue";
import SurveyEditDrawerHeader from "./SurveyEditDrawerHeader.vue";
import SurveyEditDrawerInsuredValues from "./SurveyEditDrawerInsuredValues.vue";
import SurveyEditDrawerParticipants from "./SurveyEditDrawerParticipants.vue";
import SurveyEditDrawerResponseRecipients from "./SurveyEditDrawerResponseRecipients.vue";
import SurveyEditDrawerStatus from "./SurveyEditDrawerStatus.vue";

const props = defineProps<{
  moduleId: number;
  siteId: number;
  surveyId?: number | null;
  currentUserId?: number;
}>();

const emit = defineEmits<{
  close: [void];
}>();

const isNewSurvey = computed(() => !props.surveyId);
const moduleSettings = ref<ModuleSettingsDto | null>(null);
const siteUsers = ref<UserLeanDto[] | null>(null);
const surveySettings = ref<SurveySettings | null>(null);
const lastPublishedSurvey = ref<SurveyDto | null>(null);

const { t } = useI18n({ useScope: "global" });
const { isInitializing, initializeSurveySettings, survey } = useInitializeSurveySettings();
const { isLoadingModuleSettings, getModuleSettings } = useGetModuleSettings();
const { isLoadingSite, siteData, getSite } = useGetSite();
const { getLastPublishedSurvey } = useGetLastPublishedSurvey();
const { isLoadingUsers, getUsers } = useGetUsers();
const { isSavingSurveySettings, saveSurveySettings } = useSaveSurveySettings();
const { hasValidationError, validationState, validateSurveySettings, resetValidationStateFor } =
  useValidateSurveySettings();
const { activeTab, changeTab, tabs } = useSurveyEditTabs(surveySettings);
const dialog = useDialog();
const { requestSpoeCheck, isLoadingRequestSpoeCheck } = useRequestSpoeCheck();

const isLoading = computed(
  () =>
    isInitializing.value ||
    isLoadingSite.value ||
    isLoadingModuleSettings.value ||
    isLoadingUsers.value ||
    isLoadingRequestSpoeCheck.value,
);

const update = <K extends keyof SurveySettings>(key: K, value: SurveySettings[K]) => {
  surveySettings.value![key] = value;

  if (
    key === "surveyDate" ||
    key === "spoeCheckerUserId" ||
    key === "participants" ||
    key === "status"
  ) {
    resetValidationStateFor(key);
  }
};

const showRequestSpoeCheckModal = async () => {
  // Cannot request SPOE check without ID
  if (!props.surveyId) {
    return;
  }

  const isOk = await dialog.openDialog({
    title: t("survey.editSurvey.spoeCheck.modalHeader"),
    description: t("survey.editSurvey.spoeCheck.modalText"),
    confirmText: t("survey.editSurvey.spoeCheck.request"),
  });

  if (isOk) {
    await requestSpoeCheck(props.surveyId);
  }
};

const save = async () => {
  if (!surveySettings.value || !moduleSettings.value) {
    throw new Error("Survey settings or module settings is not initialized");
  }

  validateSurveySettings(surveySettings.value, moduleSettings.value, survey.value ?? undefined);

  if (hasValidationError()) {
    return;
  }

  await saveSurveySettings(surveySettings.value, props.siteId, props.surveyId);

  if (!props.surveyId) {
    emit("close");
    return;
  }

  // Check if SPOE check should be requested
  if (
    surveySettings.value.status === Status.Complete &&
    surveySettings.value.spoeCheckerUserId &&
    survey.value &&
    (survey.value.status !== surveySettings.value.status ||
      survey.value.spoeCheckerUserId !== surveySettings.value.spoeCheckerUserId)
  ) {
    await showRequestSpoeCheckModal();
  }

  // Reload survey data after saving to enable SPOE request button
  survey.value = await getSurveyService(props.surveyId);
};

const setCopyFromPreviousSurvey = (value: boolean) => {
  if (!surveySettings.value) {
    // Should never happen unless initializeSurveySettings is broken
    return;
  }

  surveySettings.value.copyDataFromPreviousSurvey = value;

  if (value) {
    mergeParticipants(lastPublishedSurvey.value, surveySettings.value);
  }
};

// Merges participants from last published survey with current survey
const mergeParticipants = (fromSurvey: SurveyDto | null, toSurvey: SurveySettings) => {
  const toSurveyParticipants = toSurvey.participants || [];
  const fromSurveyParticipants = (fromSurvey?.participants || [])
    // Filtering out participants that already exist in the current survey
    .filter((lps) => !(toSurveyParticipants || []).some((p) => p.email === lps.email))
    // Marking copied participants as inherited from the last published survey
    .map((p) => ({ ...p, inherited: true }));

  toSurvey.participants = [...toSurveyParticipants, ...fromSurveyParticipants];
};

onBeforeMount(async () => {
  moduleSettings.value = await getModuleSettings(props.moduleId);

  [surveySettings.value, lastPublishedSurvey.value, siteUsers.value] = await Promise.all([
    initializeSurveySettings(
      props.surveyId,
      moduleSettings.value?.lossScenarios,
      props.currentUserId,
    ),
    getLastPublishedSurvey(props.siteId),
    getUsers({ moduleId: props.moduleId, siteId: props.siteId }),
    getSite(props.siteId),
  ]);

  if (!props.surveyId && !!lastPublishedSurvey.value) setCopyFromPreviousSurvey(true);
});
</script>

<template>
  <BaseDrawer
    class="survey-edit-drawer"
    :is-loading="isLoading"
    width="50"
    data-test="survey-settings-drawer"
    @close="$emit('close')"
  >
    <template #title>
      <SurveyEditDrawerHeader
        :is-new="isNewSurvey"
        :survey-date="surveySettings?.surveyDate"
        :site-name="siteData?.name"
      />
    </template>

    <BaseDrawerTabs :current-tab="activeTab" :tabs="tabs" @change="changeTab" />

    <div class="survey-edit-drawer__content">
      <div v-if="activeTab === SurveyEditTab.General" class="survey-edit-drawer__content__general">
        <SurveyEditDrawerCopy
          v-if="isNewSurvey && lastPublishedSurvey && lastPublishedSurvey.surveyId !== surveyId"
          :copy-data-from-previous-survey="surveySettings?.copyDataFromPreviousSurvey"
          :last-publish-date="lastPublishedSurvey?.surveyDate"
          @update:value="setCopyFromPreviousSurvey($event)"
        />

        <BaseCard>
          <div class="survey-edit-drawer__content__status">
            <SurveyEditDrawerDate
              :survey-date="surveySettings?.surveyDate"
              :error-message="validationState.surveyDate"
              @update:value="update('surveyDate', $event)"
            />

            <div v-if="survey">
              <SurveyEditDrawerStatus
                v-if="!isNewSurvey && surveySettings"
                :value="surveySettings!.status ?? Status.UnderConstruction"
                :require-spoe-check="!!moduleSettings?.requireSpoeCheck"
                :parents-are-published="survey.parentsArePublished"
                :error="validationState.status"
                @update:value="update('status', $event)"
              />

              <BaseAlert
                v-if="!survey.parentsArePublished"
                class="survey-edit-drawer__content__status__info"
                severity="info"
              >
                {{ t("survey.editSurvey.parentsNotPublished") }}
              </BaseAlert>
            </div>
          </div>
        </BaseCard>

        <BaseCard :title="t('survey.assignments')">
          <div class="survey-edit-drawer__content__assignments">
            <AppModuleUsersSelect
              :value="surveySettings?.surveyorUserId ?? currentUserId"
              :label="t('survey.surveyor')"
              :module-users="siteUsers"
              :filter="(user) => user.id !== surveySettings?.spoeCheckerUserId"
              clearable
              @update:value="update('surveyorUserId', $event)"
            />

            <AppModuleUsersSelect
              clearable
              :value="surveySettings?.spoeCheckerUserId"
              :label="t('survey.spoeChecker')"
              :required="!!moduleSettings?.requireSpoeCheck"
              :error-message="validationState.spoeCheckerUserId"
              :module-users="siteUsers"
              :filter="
                (user) => {
                  // Only allow EXT users to be SPOE checker if module settings allow it
                  if (user.role === Role.EXT && !moduleSettings?.allowSpoeCheckByExt) {
                    return false;
                  }

                  return user.id !== surveySettings?.surveyorUserId;
                }
              "
              @update:value="update('spoeCheckerUserId', $event)"
            />

            <SurveyEditDrawerResponseRecipients
              :recipient-ids="surveySettings?.recommendationResponseRecipients"
              :module-users="siteUsers"
              @update:value="update('recommendationResponseRecipients', $event)"
            />
          </div>
        </BaseCard>
      </div>

      <div v-if="activeTab === SurveyEditTab.Participants">
        <SurveyEditDrawerParticipants
          :participants="surveySettings?.participants"
          :errors="validationState.participants"
          :module-users="siteUsers"
          @update="update('participants', $event)"
        />
      </div>

      <div v-if="activeTab === SurveyEditTab.InsuredValues">
        <SurveyEditDrawerInsuredValues
          :insured-values="surveySettings?.insuredValues"
          :loss-scenarios="moduleSettings?.lossScenarios"
          :currency-code="moduleSettings?.currencyCode"
          @update="
            surveySettings!.insuredValues![$event.index][$event.value.type] = $event.value.value
          "
        />
      </div>
    </div>

    <template #footer-left>
      <BaseButton
        :disabled="isLoading || isSavingSurveySettings"
        data-test="survey-settings-confirm"
        @click="save"
      >
        {{ surveyId ? t("common.actions.save") : t("common.actions.create") }}
      </BaseButton>

      <BaseButton :disabled="isLoading" variant="outlined" @click="$emit('close')">
        {{ t("common.actions.cancel") }}
      </BaseButton>
    </template>

    <template #footer-right>
      <BaseButton
        v-if="survey?.status === Status.Complete && survey?.spoeCheckerUserId"
        :disabled="isLoading"
        variant="outlined"
        @click="showRequestSpoeCheckModal"
      >
        {{ t("survey.editSurvey.spoeCheck.request") }}
      </BaseButton>
    </template>
  </BaseDrawer>
</template>

<style scoped lang="scss">
.survey-edit-drawer {
  &__content {
    display: grid;
    gap: $spacing-6;
    padding: $spacing-6;

    &__general {
      display: grid;
      gap: $spacing-6;
    }

    &__status {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: $spacing-3 $spacing-6;

      &__info {
        margin-top: $spacing-3;
      }
    }

    &__assignments {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: $spacing-3 $spacing-6;
    }
  }
}
</style>
