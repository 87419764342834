<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { RecSortOrder } from "@/types/_generated/api";
import BaseOption from "@/components/base/BaseSelect/BaseOption.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";

defineProps<{
  value: RecSortOrder;
}>();

defineEmits<{
  update: [RecSortOrder];
}>();

const { t } = useI18n({ useScope: "global" });

const enumToI18nKeyMap = {
  [RecSortOrder.AgeAscending]: "ageAscending",
  [RecSortOrder.AgeDescending]: "ageDescending",
  [RecSortOrder.Grading]: "grading",
  [RecSortOrder.Questionnaire]: "questionnaire",
};

const options = computed(() =>
  Object.values(RecSortOrder).map((value) => ({
    title: t(`modules.recSortOrder.${enumToI18nKeyMap[value]}`),
    value,
  })),
);
</script>

<template>
  <BaseSelect
    :value="value"
    :label="t('modules.recommendationSortOrder')"
    @change="$emit('update', $event as RecSortOrder)"
  >
    <BaseOption v-for="option in options" :key="option.value" :value="option.value">
      {{ option.title }}
    </BaseOption>
  </BaseSelect>
</template>
