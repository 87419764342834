import { ref } from "vue";
import { useI18n } from "vue-i18n";
import type { GetRecentActivityResponse } from "@/types/_generated/api";
import { AdminRouteName } from "@/router/RouteName";
import { getRecentActivityService } from "@/services/activity";
import type { RecentActivityItem } from "../types";
import { createRecentActivityItem } from "../helpers/createRecentActivityItem";

const transformModules = (modules: GetRecentActivityResponse["modules"]): RecentActivityItem[] => {
  return modules.map((module) =>
    createRecentActivityItem(module.name, module.lastVisit, AdminRouteName.ModuleSites, {
      moduleId: module.moduleId,
    }),
  );
};

const transformSites = (sites: GetRecentActivityResponse["sites"]): RecentActivityItem[] => {
  return sites.map((site) =>
    createRecentActivityItem(site.name, site.lastVisit, AdminRouteName.Site, {
      moduleId: site.moduleId,
      siteId: site.siteId,
    }),
  );
};

const transformSurveys = (surveys: GetRecentActivityResponse["surveys"]): RecentActivityItem[] => {
  return surveys.map((survey) =>
    createRecentActivityItem(survey.name, survey.lastVisit, AdminRouteName.BlueRating, {
      moduleId: survey.moduleId,
      siteId: survey.siteId,
      surveyId: survey.surveyId,
    }),
  );
};

export const useGetRecentActivity = () => {
  const { t } = useI18n({ useScope: "global" });

  const isLoadingData = ref(false);
  const recentActivityModules = ref<RecentActivityItem[]>([]);
  const recentActivitySites = ref<RecentActivityItem[]>([]);
  const recentActivitySurveys = ref<RecentActivityItem[]>([]);

  const columns: { title: string; key: keyof RecentActivityItem }[] = [
    { title: t("common.name"), key: "title" },
    { title: "Last Visit", key: "lastVisit" },
  ];

  const getRecentActivity = async (count?: number) => {
    isLoadingData.value = true;

    try {
      const response = await getRecentActivityService(count);

      if (!response) {
        throw new Error("Recent activity response is null");
      }

      recentActivityModules.value = transformModules(response.modules);
      recentActivitySites.value = transformSites(response.sites);
      recentActivitySurveys.value = transformSurveys(response.surveys);
    } finally {
      isLoadingData.value = false;
    }
  };

  return {
    isLoadingData,
    getRecentActivity,
    recentActivityColumns: columns,
    recentActivityModules,
    recentActivitySites,
    recentActivitySurveys,
  };
};
