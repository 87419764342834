<script setup lang="ts">
import { useI18n } from "vue-i18n";
import type { DeepReadonly } from "vue";
import type { GroupBy } from "@/components/mods/Filter/SiteDefinitions/types/GroupBy";
import type { SitesData } from "@/composables/services/useGetSitesData";
import { useDownloadBlob } from "@/composables/useDownloadBlob";
import {
  ExecReportSortOrder,
  SearchExecSummaryExcel,
  type ExecutiveReportLossScenarioDto,
} from "@/types/_generated/api";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import ModFilterSiteDefinitionsGroupBy from "@/components/mods/Filter/SiteDefinitions/ModFilterSiteDefinitionsGroupBy.vue";
import BlueRatingTableToolbar from "@/components/shared/BlueRatingTable/BlueRatingTableToolbar.vue";
import type { ExecutiveSummaryDrawer } from "../../types/ExecSummaryDrawers";
import { DrawerOption } from "../../constants/DrawerOption";
import { mergeFilters } from "../../helpers/mergeFilters";
import { ClientFilter, ServerFilter } from "../../types/FilterTypes";
import ExecSummaryToolbarSortBy from "./ExecSummaryToolbarSortBy.vue";

const { blobDownload } = useDownloadBlob();

const props = defineProps<{
  moduleId: number;
  isLoadingData?: boolean;
  lossScenarios?: DeepReadonly<ExecutiveReportLossScenarioDto[]>;
  siteDefinitions: DeepReadonly<SitesData>;
  clientFilter: ClientFilter;
  serverFilter: ServerFilter;
}>();

const emit = defineEmits<{
  openDrawer: [ExecutiveSummaryDrawer];
  applyServerFilter: [ServerFilter];
}>();

const { t } = useI18n({ useScope: "global" });

const downloadExecSummaryExcel = () => {
  const url = `/v1/modules/${props.moduleId}/reports/executive-summary/excel`;

  const body = mergeFilters(props.moduleId, props.clientFilter, props.serverFilter);

  blobDownload.post<SearchExecSummaryExcel>(url, {
    visibleLossScenarioIds: getVisibleLossScenarios(),
    ...body,
  });
};

const getVisibleLossScenarios = () =>
  props.lossScenarios
    ?.filter((ls) => props.clientFilter.selectedColumns.has(`lossScenario-${ls.id}`))
    .map((ls) => ls.id) ?? [];

const onUpdateGroupBy = (groupBy: GroupBy) => {
  emit("applyServerFilter", {
    ...props.serverFilter,
    groupBySiteDefinition: groupBy,
  });
};

const onUpdateSortBy = (sortBy: ExecReportSortOrder, sortByLossScenarioId?: number) => {
  emit("applyServerFilter", {
    ...props.serverFilter,
    sortBy,
    sortByLossScenarioId,
  });
};
</script>

<template>
  <BlueRatingTableToolbar>
    <template #left>
      <BaseButton
        variant="outlined"
        :disabled="isLoadingData"
        :title="t('common.filter')"
        @click="$emit('openDrawer', { type: DrawerOption.Filter })"
      >
        <BaseIcon size="medium" icon="filter" />
      </BaseButton>

      <ExecSummaryToolbarSortBy
        :disabled="isLoadingData"
        :loss-scenarios="lossScenarios"
        :current-sort-order="serverFilter.sortBy"
        :sort-by-loss-scenario-id="serverFilter.sortByLossScenarioId"
        @sort-table="onUpdateSortBy"
      />

      <ModFilterSiteDefinitionsGroupBy
        :disabled="isLoadingData"
        :site-definitions="siteDefinitions"
        :value="serverFilter.groupBySiteDefinition"
        @change="onUpdateGroupBy"
      />
    </template>

    <template #right>
      <BaseButton
        variant="outlined"
        :disabled="isLoadingData"
        :title="t('common.actions.exportExcel')"
        @click="downloadExecSummaryExcel"
      >
        <BaseIcon size="medium" icon="excel" />
      </BaseButton>
    </template>
  </BlueRatingTableToolbar>
</template>
