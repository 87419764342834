<script setup lang="ts">
import { toRef } from "vue";
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@/composables/useVuelidateWithFocusError";
import { ModuleDto, ReportMailLoginType } from "@/types/_generated/api";
import { useModuleValidation } from "@/views/Admin/Modules/composables/useModuleValidation";
import BaseCard from "@/components/base/BaseCard/BaseCard.vue";
import BaseEditor from "@/components/base/BaseEditor/BaseEditor.vue";
import BaseLabel from "@/components/base/BaseLabel.vue";
import BaseOption from "@/components/base/BaseSelect/BaseOption.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";

const { t } = useI18n({ useScope: "global" });

const props = defineProps<{
  module: ModuleDto;
}>();

defineEmits<{
  (event: "update:module", module: ModuleDto): void;
}>();

const { emailTemplatesValidation } = useModuleValidation();

const { v$ } = useVuelidate(
  emailTemplatesValidation,
  toRef(() => props.module),
  {
    $registerAs: "edit-module-email-templates",
    $autoDirty: true,
  },
);
</script>

<template>
  <div class="edit-module-email">
    <BaseCard :title="t('survey.preSurveyEmail.preSurveyEmail')">
      <div class="edit-module-email__content">
        <BaseTextField
          :label="t('survey.preSurveyEmail.subject')"
          :value="module.preSurveyMailSubject"
          :maxlength="100"
          data-test="module-pre-survey-subject"
          :errors="v$?.preSurveyMailSubject?.$errors"
          @update:value="
            $emit('update:module', {
              ...module,
              preSurveyMailSubject: $event as string,
            })
          "
        />
        <BaseLabel>{{ t("survey.preSurveyEmail.body") }}</BaseLabel>
        <BaseEditor
          :custom-height="{ min: '200px' }"
          :errors="v$?.preSurveyMailBody?.$errors"
          :value="module.preSurveyMailBody"
          data-test="module-pre-survey-body"
          @input="
            $emit('update:module', {
              ...module,
              preSurveyMailBody: $event as string,
            })
          "
        />
      </div>
    </BaseCard>
    <BaseCard :title="t('survey.surveyReportMail.surveyReportMail')">
      <div class="edit-module-email__content">
        <BaseSelect
          :label="t('modules.recResponseMfa')"
          :value="module.reportMailLoginType"
          @change="
            $emit('update:module', {
              ...module,
              reportMailLoginType: $event as ReportMailLoginType,
            })
          "
        >
          <BaseOption
            v-for="option in Object.values(ReportMailLoginType)"
            :key="option"
            :value="option"
          >
            {{ t(`common.reportMailLoginType.${option.toLowerCase()}`) }}
          </BaseOption>
        </BaseSelect>

        <BaseTextField
          :label="t('survey.surveyReportMail.subject')"
          :value="module.reportMailSubject"
          :maxlength="100"
          data-test="module-report-subject"
          :errors="v$?.reportMailSubject?.$errors"
          @update:value="
            $emit('update:module', {
              ...module,
              reportMailSubject: $event as string,
            })
          "
        />
        <BaseLabel>{{ t("survey.surveyReportMail.body") }}</BaseLabel>
        <BaseEditor
          :custom-height="{ min: '200px' }"
          :errors="v$?.reportMailBody?.$errors"
          :value="module.reportMailBody"
          data-test="module-report-body"
          @input="
            $emit('update:module', {
              ...module,
              reportMailBody: $event as string,
            })
          "
        />
      </div>
    </BaseCard>
  </div>
</template>

<style scoped lang="scss">
.edit-module-email {
  @include grid-columns(1);
  &__content {
    display: flex;
    flex-direction: column;
    gap: $spacing-4;
  }
}
</style>
