import { reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import type {
  BlueRatingDto,
  BlueRatingRecommendationDto,
  RecResponseModuleTranslationsDto,
  RecResponseSurveyDto,
} from "@/types/_generated/api";
import { getRecResponseSurveyService, submitRecResponsesService } from "../services/recResponse";

export const useRecResponseSurvey = () => {
  const { t } = useI18n({ useScope: "global" });

  const allowedFileTypes = ref<string[]>([]);
  const blueRating = ref<BlueRatingDto | null>(null);
  const currency = ref<string>("");
  const isLoadingSurvey = ref(false);
  const recommendations = ref<BlueRatingRecommendationDto[]>([]);
  const surveyInfo = reactive({
    moduleName: "",
    siteName: "",
    surveyDate: "",
  });
  const translations = ref<RecResponseModuleTranslationsDto>({
    abeyanceTranslation: t("blueRating.surveyStatus.abeyance"),
    textForBlue: t("blueRating.colors.blue"),
    textForGreen: t("blueRating.colors.green"),
    textForYellow: t("blueRating.colors.yellow"),
    textForRed: t("blueRating.colors.red"),
  });

  const updateFromSurvey = (survey: RecResponseSurveyDto) => {
    blueRating.value = survey.blueRating;
    currency.value = survey.moduleCurrency;
    recommendations.value = survey.recommendations;
    translations.value = survey.moduleTranslations;

    surveyInfo.moduleName = survey.surveyInfo.moduleName;
    surveyInfo.siteName = survey.surveyInfo.siteName;
    surveyInfo.surveyDate = survey.surveyInfo.surveyDate;
  };

  const getSurvey = async () => {
    isLoadingSurvey.value = true;

    const response = await getRecResponseSurveyService();

    if (response) {
      allowedFileTypes.value = response.fileExtensions;
      updateFromSurvey(response.survey);
    }

    isLoadingSurvey.value = false;
  };

  const submitRecResponses = async () => {
    isLoadingSurvey.value = true;
    const survey = await submitRecResponsesService();

    if (survey) {
      updateFromSurvey(survey);
    }

    isLoadingSurvey.value = false;
  };

  return {
    allowedFileTypes,
    blueRating,
    currency,
    getSurvey,
    isLoadingSurvey,
    surveyInfo,
    recommendations,
    submitRecResponses,
    translations,
  };
};
