<script setup lang="ts">
import { useI18n } from "vue-i18n";
import type { BlueRatingRecommendationDto } from "@/types/_generated/api";
import { formatDate } from "@/helpers/formatDate";
import BaseDataDisplayList from "@/components/base/BaseDataDisplay/BaseDataDisplayList.vue";
import BaseDataDisplayListItem from "@/components/base/BaseDataDisplay/BaseDataDisplayListItem.vue";

defineProps<{
  recommendation: BlueRatingRecommendationDto;
  abeyanceDateTranslation?: string;
  abeyanceCommentTranslation?: string;
}>();

const { t } = useI18n({ useScope: "global" });
</script>

<template>
  <BaseDataDisplayList>
    <BaseDataDisplayListItem
      :label="abeyanceDateTranslation ?? t('userWeb.onlineReport.recommendations.abeyanceDate')"
    >
      <p>{{ formatDate.asDateOnly(recommendation.abeyanceDate) }}</p>
    </BaseDataDisplayListItem>
    <BaseDataDisplayListItem
      :label="
        abeyanceCommentTranslation ?? t('userWeb.onlineReport.recommendations.abeyanceComment')
      "
    >
      <p>{{ recommendation.abeyanceComment }}</p>
    </BaseDataDisplayListItem>
  </BaseDataDisplayList>
</template>
