<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import type { TrackedRecommendation } from "@/composables/services/useSearchRecommendationTracking";
import { useDownloadBlob } from "@/composables/useDownloadBlob";
import { toCurrency } from "@/helpers/formatNumber";
import { BlueRatingRecommendationDto } from "@/types/_generated/api";
import { ModuleColorTranslations } from "@/types/ModuleTranslations";
import { useRecommendationTrackingColumns } from "@/views/UserWeb/Sites/composables/useSiteRecTrackingColumns";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import BaseLabel from "@/components/base/BaseLabel.vue";
import RecTracking from "@/components/shared/RecTracking/RecTracking.vue";

const props = defineProps<{
  isLoading: boolean;
  moduleColorTranslations: ModuleColorTranslations;
  moduleCurrency: string;
  trackedRecommendations: TrackedRecommendation[];
  blueRatingRecommendations: BlueRatingRecommendationDto[];
}>();

const { t } = useI18n({ useScope: "global" });
const { columns } = useRecommendationTrackingColumns();

const totalCost = computed(() =>
  props.trackedRecommendations.reduce(
    (total, recommendation) => total + (recommendation.siteResponseCostToComplete ?? 0),
    0,
  ),
);

const { blobDownload } = useDownloadBlob();

const handleDownload = () =>
  blobDownload.get("/v1/recommendation-response/reports/recommendation-tracking/excel");
</script>

<template>
  <div class="rec-resp-summary-rec-trac-details">
    <RecTracking
      :columns="columns"
      :is-loading="isLoading"
      :tracked-recommendations="trackedRecommendations"
      :module-color-translations="moduleColorTranslations"
      :pre-loaded-recommendations="blueRatingRecommendations"
    >
      <template #control-actions>
        <div class="rec-resp-summary-rec-trac-details__top">
          <div class="rec-resp-summary-rec-trac-details__top__cost">
            <BaseLabel>{{ t("userWeb.siteRecommendationTracking.totalCost") }}:</BaseLabel>
            <p class="rec-resp-summary-rec-trac-details__top__cost__value">
              {{ toCurrency(totalCost, moduleCurrency) }}
            </p>
          </div>

          <BaseButton size="medium" variant="outlined" :title="t('common.actions.exportExcel')">
            <BaseIcon size="medium" icon="excel" @click="handleDownload()" />
          </BaseButton>
        </div>
      </template>
    </RecTracking>
  </div>
</template>

<style scoped lang="scss">
.rec-resp-summary-rec-trac-details {
  margin-top: $spacing-8;
  display: flex;
  flex-direction: column;
  width: 100%;

  &__top {
    display: flex;
    align-items: center;
    gap: $spacing-8;

    &__cost {
      display: flex;
      gap: $spacing-1;
      font-size: $text-sm;

      &__value {
        color: $primary-7;
      }
    }
  }
}
</style>
