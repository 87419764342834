import { Ref } from "vue";
import { useI18n } from "vue-i18n";
import { BlueColor } from "@/types/_generated/api";
import { ModuleColorTranslations } from "@/types/ModuleTranslations";

export const useBlueColorTranslations = (translations?: Ref<ModuleColorTranslations | null>) => {
  const { t } = useI18n({ useScope: "global" });

  const getLabel = (color: BlueColor) => {
    if (!translations?.value) {
      return t(`common.statuses.${color}`);
    }

    switch (color) {
      case BlueColor.Blue:
        return translations.value.textForBlue.length
          ? translations.value.textForBlue
          : t("common.statuses.Blue");
      case BlueColor.Green:
        return translations.value.textForGreen.length
          ? translations.value.textForGreen
          : t("common.statuses.Green");
      case BlueColor.Yellow:
        return translations.value.textForYellow.length
          ? translations.value.textForYellow
          : t("common.statuses.Yellow");
      case BlueColor.Red:
        return translations.value.textForRed.length
          ? translations.value.textForRed
          : t("common.statuses.Red");
      default:
        return t(`common.statuses.${color}`);
    }
  };

  return { getLabel };
};
