<script setup lang="ts">
import { computed, onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useArchivedStatusFilter } from "@/composables/useArchivedStatusFilter";
import { useBroadcastService } from "@/composables/useBroadcastService";
import { useDrawerState } from "@/composables/useDrawerState";
import { useTableFilter } from "@/composables/useTableFilter";
import { formatDate } from "@/helpers/formatDate";
import { authorize } from "@/plugins/can";
import { AdminRouteName, UserWebRouteName } from "@/router/RouteName";
import { getSurveysService, surveyEvents } from "@/services/surveys";
import { useAppStore } from "@/stores/app";
import { Status, SurveyLeanDto } from "@/types/_generated/api";
import { ColumnDataType, ColumnDefinition } from "@/types/ColumnDefinition";
import SurveyPreSurveyMailDrawer from "@/views/Admin/Sites/components/SurveyPreSurveyMailDrawer.vue";
import SurveyUploadReportDrawer from "@/views/Admin/Sites/components/SurveyUploadReportDrawer.vue";
import BaseDropdownMenu from "@/components/base/BaseDropdownMenu/BaseDropdownMenu.vue";
import BaseEntityLink from "@/components/base/BaseEntityLink.vue";
import BaseFilter from "@/components/base/BaseFilter.vue";
import BaseGridTable from "@/components/base/BaseGridTable/BaseGridTable.vue";
import BaseGridTableEntityLinkHeader from "@/components/base/BaseGridTable/components/BaseGridTableEntityLinkHeader.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import BaseStatusBadge from "@/components/base/BaseStatusBadge.vue";
import EditIconButton from "@/components/shared/EditIconButton.vue";
import { useHasReportMailPermission } from "@/components/shared/ReportMailDrawer/composables/useHasReportMailPermission";
import ReportMailDrawer from "@/components/shared/ReportMailDrawer/ReportMailDrawer.vue";
import UserLink from "@/components/shared/UserLink.vue";
import UserDetailsDrawer from "../../Users/components/UserDetailsDrawer/UserDetailsDrawer.vue";
import SurveyEditDrawer from "./SurveyEditDrawer/SurveyEditDrawer.vue";

const props = defineProps<{
  moduleId: number;
  siteId: number;
}>();

const { t } = useI18n({ useScope: "global" });
const router = useRouter();
const surveys = ref<SurveyLeanDto[]>([]);
const isLoading = ref(false);
const showArchived = ref(false);

const surveyId = ref<number | null>();
const showSurveyEditDrawer = ref(false);

const app = useAppStore();
const { filterArchived, archivedMenuOption } = useArchivedStatusFilter(showArchived);

const { hasReportMailPermission } = useHasReportMailPermission(props.moduleId);

useBroadcastService(surveyEvents, (message) => loadSurveys());

const loadSurveys = async () => {
  isLoading.value = true;

  surveys.value = (await getSurveysService(props.siteId)) || [];

  isLoading.value = false;
};

const toggleSurveyEditDrawer = (id?: number) => {
  surveyId.value = id;
  showSurveyEditDrawer.value = !showSurveyEditDrawer.value;
};

onBeforeMount(async () => await loadSurveys());

const columns = [
  new ColumnDefinition({
    title: t("surveys.surveyDate"),
    key: "surveyDate",
    type: ColumnDataType.Date,
    autoWidth: true,
  }),
  new ColumnDefinition({
    title: t("surveys.surveyor"),
    key: "surveyorName",
  }),
  new ColumnDefinition({
    title: t("surveys.surveysStatus"),
    key: "status",
    autoWidth: true,
  }),
  new ColumnDefinition({
    title: t("surveys.spoeCheckerName"),
    key: "spoeCheckerName",
  }),
  new ColumnDefinition({
    title: t("surveys.spoeCheckStatus"),
    key: "isSpoeCheckApproved",
    type: ColumnDataType.Boolean,
    autoWidth: true,
  }),
] as ColumnDefinition<SurveyLeanDto>[];

const getSpoeCheckerStatus = (
  spoeCheckerId: number | null | undefined,
  isSpoeCheckApproved: boolean | null | undefined,
) => {
  // No spoe checker means SPOE check is not required
  if (!spoeCheckerId) {
    return "";
  }

  if (isSpoeCheckApproved === false) {
    return t("survey.spoeCheckerStatus.notApproved");
  }

  if (isSpoeCheckApproved === true) {
    return t("survey.spoeCheckerStatus.approved");
  }

  return t("survey.spoeCheckerStatus.pending");
};

const { result, searchPhrase, searchKey, setSearchPhrase, setSearchKey, searchKeys } =
  useTableFilter(surveys, columns, [filterArchived]);

const activeDrawer = ref("");

const openDrawer = (drawer: string, id: number) => {
  activeDrawer.value = drawer;
  surveyId.value = id;
};

const closeDrawer = () => {
  activeDrawer.value = "";
  surveyId.value = null;
};

const {
  closeDrawer: closeUserDetailDrawer,
  drawerData: userId,
  openDrawer: openUserDetailDrawer,
  showDrawer: showUserDetailDrawer,
} = useDrawerState<number>();

const goToBlueRating = (surveyId: number) => {
  router.push({
    name: AdminRouteName.BlueRating,
    params: { moduleId: props.moduleId, siteId: props.siteId, surveyId },
  });
};

const canEdit = computed(() => authorize.hasModulePermission(props.moduleId, "editSurvey"));
</script>

<template>
  <BaseGridTable
    :rows="result.result"
    :total-row-count="result.all.length"
    :columns="columns"
    :is-loading="isLoading"
    storage-key="siteSurveysTable"
    :custom-options="app.isAdmin ? [archivedMenuOption] : []"
  >
    <template #controls>
      <BaseFilter
        :search-phrase="searchPhrase"
        :search-key="searchKey"
        :search-keys="searchKeys"
        @update:search-phrase="setSearchPhrase($event)"
        @update:search-key="setSearchKey($event)"
      />
    </template>

    <template #header-surveyorName="{ column }">
      <BaseGridTableEntityLinkHeader :title="column.title" :label="column.title" />
    </template>
    <template #header-surveyDate="{ column }">
      <BaseGridTableEntityLinkHeader :title="column.title" :label="column.title" />
    </template>

    <template #column-surveyDate="{ row }">
      <BaseIcon
        v-if="canEdit"
        icon="info"
        :title="t('survey.showSurveyDetails')"
        @click="toggleSurveyEditDrawer(row.surveyId)"
      />
      <BaseEntityLink
        :title="t('navigation.goTo', { entity: t('navigation.blueRating') })"
        :label="formatDate.asDateOnly(row.surveyDate)"
        @click="goToBlueRating(row.surveyId)"
      >
      </BaseEntityLink>
    </template>

    <template #column-spoeCheckerName="{ row }">
      <UserLink
        v-if="row.spoeCheckerId"
        :label="row.spoeCheckerName!"
        :user-id="row.spoeCheckerId"
        :user-role="row.spoeCheckerRole!"
        @click="openUserDetailDrawer"
      />
    </template>
    <template #column-surveyorName="{ row }">
      <UserLink
        v-if="row.surveyorId"
        :label="row.surveyorName!"
        :user-id="row.surveyorId!"
        :user-role="row.surveyorRole!"
        @click="openUserDetailDrawer"
      />
    </template>
    <template #column-status="{ row }">
      <BaseStatusBadge :status="row.status" />
    </template>
    <template #column-isSpoeCheckApproved="{ row }">
      {{ getSpoeCheckerStatus(row.spoeCheckerId, row.isSpoeCheckApproved) }}
    </template>

    <template #actions="{ row }">
      <BaseDropdownMenu
        v-if="canEdit"
        :position="['bottom', 'left']"
        :options="[
          {
            label: t('navigation.blueRating'),
            action: () =>
              router.push({
                name: AdminRouteName.BlueRating,
                params: { moduleId, siteId, surveyId: row.surveyId },
              }),
          },
          ...(app.user?.id && row.status === Status.Complete && row.spoeCheckerId === app.user.id
            ? [
                {
                  label: t('survey.userWebSpoeCheck'),
                  action: () =>
                    router.push({
                      name: UserWebRouteName.SpoeCheck,
                      params: { moduleId, siteId, surveyId: row.surveyId },
                    }),
                },
              ]
            : []),
          {
            label: t('survey.preSurveyEmail.preSurveyEmail'),
            action: () => openDrawer('surveyPreSurveyMailDrawer', row.surveyId),
          },
          ...(row.status === 'Published' && hasReportMailPermission
            ? [
                {
                  label: t('survey.surveyReportMail.surveyReportMail'),
                  action: () => openDrawer('surveyReportMailDrawer', row.surveyId),
                },
              ]
            : []),
          {
            label: t('survey.uploadReport.reports'),
            action: () => openDrawer('surveyUploadReportDrawer', row.surveyId),
          },
        ]"
      />
      <EditIconButton
        v-if="canEdit"
        :title="t('survey.showSurveyDetails')"
        @click="toggleSurveyEditDrawer(row.surveyId)"
      />
    </template>
  </BaseGridTable>

  <SurveyEditDrawer
    v-if="showSurveyEditDrawer"
    :site-id="props.siteId"
    :module-id="props.moduleId"
    :survey-id="surveyId"
    @close="toggleSurveyEditDrawer()"
  />

  <SurveyPreSurveyMailDrawer
    v-if="activeDrawer === 'surveyPreSurveyMailDrawer'"
    :survey-id="surveyId!"
    @close="closeDrawer"
  />

  <ReportMailDrawer
    v-if="activeDrawer === 'surveyReportMailDrawer'"
    :survey-id="surveyId!"
    :module-id="moduleId"
    @close="closeDrawer"
  />

  <SurveyUploadReportDrawer
    v-if="activeDrawer === 'surveyUploadReportDrawer'"
    :survey-id="surveyId!"
    @close="closeDrawer"
  />

  <UserDetailsDrawer
    v-if="showUserDetailDrawer && userId"
    :user-id="userId"
    @close="closeUserDetailDrawer"
  />
</template>
